import { extendTheme } from '@mui/joy/styles';

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        background: {
          body: '#FBFCFE',
        },
      },
    },
  },
});

export default theme;
