/* eslint-disable no-underscore-dangle */
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import {
  Typography,
  IconButton,
  useTheme,
  Theme,
  Tooltip,
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from '@mui/joy';
import {
  ArrowBack,
  Download,
  OpenInNew,
  Save,
  LockOpen,
  CheckCircle,
} from '@mui/icons-material';
import PDFMultiPageViewer from 'src/components/PDFMultiPageViewer';
import { tss } from 'tss-react';
import {
  GetProjectResponse,
  LeadershipBios,
  RevenueMix,
} from 'src/generated/api';
import { getAPI } from 'src/api';
import { getConfig } from 'src/config';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingContainer from 'src/components/LoadingContainer';
import { SnackbarContext } from 'src/contexts/snackbar';
import { CIMData, ShowSections } from './types';
import BasicInfo from './BasicInfo';
import ProductServices from './ProductServices';
import Financials from './Financials';
import CompanyOverview from './CompanyOverview';
import Snapshot from './Snapshot';
import InvestmentConsiderations from './InvestmentConsiderations';
import Leadership from './Leadership';
import Revenue from './Revenue';
import Vendors from './Vendors';
import GTM from './Gtm';
import Growth from './Growth';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      maxWidth: 1600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    tabList: {
      position: 'sticky',
      top: theme.spacing(10),
      alignSelf: 'flex-start',
      minWidth: 260,
      flexShrink: 0,
      maxHeight: `calc(100vh - ${theme.spacing(10)})`,
      overflowY: 'auto',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      gap: theme.spacing(2),
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      width: '100%',
      position: 'sticky',
      top: 0,
      backgroundColor: theme.palette.background.body,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      zIndex: 10,
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      boxShadow: theme.shadow.md,
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
    tabs: {
      boxShadow: theme.shadow.md,
      marginTop: theme.spacing(1),
      flexGrow: 1,
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
    },
    pdfPreviewWrapper: {
      flexShrink: 0,
      position: 'sticky',
      top: theme.spacing(10),
      alignSelf: 'flex-start',
      height: `calc(100vh - ${theme.spacing(10)})`,
      overflowY: 'auto',
      marginLeft: theme.spacing(2),
    },
  }));

export function CIM() {
  const { id } = useParams();
  const theme = useTheme();
  const config = getConfig();
  const cimUrl = `${config.hosts.api}/clients/${id}/download_cim`;
  const api = getAPI();
  const [tabNum, setTabNum] = useState<number>(0);
  const [productTabNum, setProductTabNum] = React.useState(0);
  const [project, setProject] = useState<GetProjectResponse | null>(null);
  const [showSections, setShowSections] = useState<ShowSections>({
    revenueMix: true,
    revenueGeo: true,
    customerConcentration: true,
    customerAcquisition: true,
    growthOpportunities: true,
    vendorsAndPartners: true,
  });
  const { classes } = useStyles({ theme });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useForm<CIMData>();

  const [pageNumbers, setPageNumbers] = useState({
    numLeadershipPages: 0,
    numProductPages: 0,
    numRevenuePages: 0,
    numVendorsPages: 0,
    numGtmPages: 0,
    numGrowthPages: 0,
  });

  const calculatePageNumbers = useCallback((data: CIMData, s: ShowSections) => {
    const numLeadershipPages = Math.ceil((data.leadershipBios?.length ?? 0) / 2);
    const numProductPages = data.productsAndServices?.length ?? 0;
    let numRevenuePages: number;
    if (!s.revenueMix && !s.revenueGeo &&
      !s.customerConcentration) {
      numRevenuePages = 0;
    } else if (s.customerConcentration &&
      (s.revenueGeo || s.revenueMix)) {
      numRevenuePages = 2;
    } else {
      numRevenuePages = 1;
    }

    const numVendorsPages = s.vendorsAndPartners ? 1 : 0;
    const numGtmPages = s.customerAcquisition ? 1 : 0;
    const numGrowthPages = s.growthOpportunities ? 1 : 0;

    setPageNumbers({
      numLeadershipPages,
      numProductPages,
      numRevenuePages,
      numVendorsPages,
      numGtmPages,
      numGrowthPages,
    });
  }, []);

  const fetchData = useCallback(async () => {
    const res = await api.clients.getProject(id!);
    setProject(res.data);
    const data = {
      name: res.data.cimData?.name ?? '',
      ownerName: res.data.cimData?.ownerName ?? '',
      ownerTitle: res.data.cimData?.ownerTitle ?? '',
      advisorName: res.data.advisorName ?? '',
      advisorTitle: res.data.advisorTitle ?? '',
      advisorEmail: res.data.advisorEmail ?? '',
      firmName: res.data.firmName ?? '',
      oneLinerDescription: res.data.cimData?.oneLinerDescription ?? '',
      companyOverview: res.data.cimData?.companyOverview ?? '',
      processBlurb: res.data.cimData?.processBlurb ?? '',
      valueProps: res.data.cimData?.valueProps?.map((value) => ({ value })) || [],
      investmentConsiderations: res.data.cimData?.investmentConsiderations
        ?.map((value) => ({ value })) || [],
      peopleSummary: res.data.cimData?.peopleSummary?.map((value) => ({ value })) || [],
      customerSummary: res.data.cimData?.customerSummary?.map((value) => ({ value })) || [],
      growthSummary: res.data.cimData?.growthSummary?.map((value) => ({ value })) || [],
      verticalsSummary: res.data.cimData?.verticalsSummary?.map((value) => ({ value })) || [],
      productsAndServices: res.data.cimData?.productsAndServices?.map((ps) => ({
        ...ps,
        features: ps.features.map((value) => ({ value })),
        valueProps: ps.valueProps.map((value) => ({ value })),
      })) || [],
      leadershipBios: (res.data.intakeData?.leadershipBios || []) as LeadershipBios,
      revenueMix: (res.data.intakeData?.revenueMix || []) as RevenueMix,
      revenueGeoDistribution: res.data.intakeData?.revenueGeoDistribution.map((item) => ({
        ...item,
        percentage: item.percentage.toString(),
      })) || [],
      topCustomersByRevenue: res.data.intakeData?.topCustomersByRevenue
        ?.map((v) => v?.toString() ?? '') ?? Array(10).fill(''),
      balanceSheetImgUrl: res.data.cimData?.balanceSheetImgUrl ?? '',
      incomeStatementImgUrl: res.data.cimData?.incomeStatementImgUrl ?? '',
      footerLogoUrl: res.data.cimData?.footerLogoUrl ?? '',
      vendorsAndPartners: res.data.intakeData?.vendersAndPartners?.map((v) => ({ value: v })) || [],
      acquisitionChannels: res.data.intakeData?.acquisitionChannels?.map((ac) => ({
        name: ac.name,
        percentage: ac.percentage,
      })) || [],
      customerAcquisitionHighlights: res.data.cimData?.customerAcquisitionHighlights
        ?.map((v) => ({ value: v })) || [],
      marketingStrategyHighlights: res.data.cimData?.marketingStrategyHighlights
        ?.map((v) => ({ value: v })) || [],
      keyMetricsHighlights: res.data.cimData?.keyMetricsHighlights
        ?.map((v) => ({ value: v })) || [],
      growthOppertunities: res.data.cimData?.growthOppertunities
        ?.map((go) => ({
          ...go,
          keyPoints: go.keyPoints.map((kp) => ({ value: kp })),
        })) || [],
    };
    reset(data);
    const newShowSections = {
      revenueMix: res.data.intakeData?.showRevenueMix ?? true,
      revenueGeo: res.data.intakeData?.showRevenueGeo ?? true,
      customerConcentration: res.data.intakeData?.showCustomerConcentration ?? true,
      customerAcquisition: res.data.intakeData?.showCustomerAcquisition ?? true,
      growthOpportunities: res.data.intakeData?.showGrowthOpportunities ?? true,
      vendorsAndPartners: res.data.intakeData?.showVendorsAndPartners ?? true,
    };
    setShowSections(newShowSections);
    calculatePageNumbers(data, newShowSections);
  }, [api, id, reset, calculatePageNumbers]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const { showSnackbar } = useContext(SnackbarContext);

  const onSubmit = useCallback(async (data: CIMData) => {
    const transformedData = {
      footerLogoUrl: data.footerLogoUrl,
      name: data.name,
      ownerName: data.ownerName,
      ownerTitle: data.ownerTitle,
      advisorName: data.advisorName,
      advisorTitle: data.advisorTitle,
      advisorEmail: data.advisorEmail,
      processBlurb: data.processBlurb,
      firmName: data.firmName,
      oneLinerDescription: data.oneLinerDescription,
      companyOverview: data.companyOverview,
      valueProps: data.valueProps?.map((item) => item.value) || [],
      investmentConsiderations: data.investmentConsiderations.map((item) => item.value),
      peopleSummary: data.peopleSummary.map((item) => item.value),
      customerSummary: data.customerSummary.map((item) => item.value),
      growthSummary: data.growthSummary.map((item) => item.value),
      verticalsSummary: data.verticalsSummary.map((item) => item.value),
      productsAndServices: data.productsAndServices.map((ps) => ({
        ...ps,
        features: ps.features.map((item) => item.value),
        valueProps: ps.valueProps.map((item) => item.value),
      })),
      leadershipBios: data.leadershipBios,
      balanceSheetImgUrl: data.balanceSheetImgUrl ?? '',
      incomeStatementImgUrl: data.incomeStatementImgUrl ?? '',
      revenueMix: data.revenueMix,
      revenueGeoDistribution: data.revenueGeoDistribution.map((item) => ({
        ...item,
        percentage: parseFloat(item.percentage),
      })),
      topCustomersByRevenue: data.topCustomersByRevenue
        .map((v) => (v ? parseFloat(v) : null))
        .filter((v): v is number => v !== null),
      vendorsAndPartners: data.vendorsAndPartners.map((item) => item.value),
      acquisitionChannels: data.acquisitionChannels,
      customerAcquisitionHighlights: data.customerAcquisitionHighlights.map((item) => item.value),
      marketingStrategyHighlights: data.marketingStrategyHighlights.map((item) => item.value),
      keyMetricsHighlights: data.keyMetricsHighlights.map((item) => item.value),
      growthOppertunities: data.growthOppertunities.map((go) => ({
        ...go,
        keyPoints: go.keyPoints.map((kp) => kp.value),
      })),
      showRevenueMix: showSections.revenueMix,
      showRevenueGeo: showSections.revenueGeo,
      showCustomerConcentration: showSections.customerConcentration,
      showCustomerAcquisition: showSections.customerAcquisition,
      showGrowthOpportunities: showSections.growthOpportunities,
      showVendorsAndPartners: showSections.vendorsAndPartners,
    };

    try {
      await api.clients.updateCim(id!, transformedData);
    } catch (error) {
      showSnackbar({
        color: 'danger',
        message: 'Error updating CIM',
      });
    }
  }, [
    api.clients,
    id,
    showSections.customerAcquisition,
    showSections.customerConcentration,
    showSections.growthOpportunities,
    showSections.revenueGeo,
    showSections.revenueMix,
    showSections.vendorsAndPartners,
    showSnackbar,
  ]);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [currentPreviewPages, setCurrentPreviewPages] = useState<number[]>([]);

  const onSave = useCallback(async () => {
    const data = getValues();
    if (!data.productsAndServices) {
      return;
    }
    await onSubmit(data);
    showSnackbar({
      message: 'Changes saved successfully',
      color: 'success',
    });
    setRefreshTrigger((prev) => prev + 1);
    // Recalculate page numbers on save
    calculatePageNumbers(data, showSections);
  }, [getValues, onSubmit, showSnackbar, calculatePageNumbers, showSections]);

  const onFinalize = async () => {
    await handleSubmit(async (data) => {
      await onSubmit(data);
      await api.clients.lockCim(id!);
      await fetchData();
      showSnackbar({
        message: 'Finalized CIM!',
        color: 'success',
      });
    }, () => {
      showSnackbar({
        message: 'Please fill in all required fields before finalizing.',
        color: 'danger',
      });
    })();
  };

  const onUnlock = async () => {
    await api.clients.unlockCim(id!);
    await fetchData();
  };

  const navigate = useNavigate();

  useEffect(() => {
    const {
      numLeadershipPages,
      numProductPages,
      numRevenuePages,
      numVendorsPages,
      numGtmPages,
      numGrowthPages,
    } = pageNumbers;

    if (tabNum === 0) {
      // Basic Info
      setCurrentPreviewPages([1, 2]);
    } else if (tabNum === 1) {
      // Company Overview
      setCurrentPreviewPages([3]);
    } else if (tabNum === 2) {
      // Snapshot
      setCurrentPreviewPages([4]);
    } else if (tabNum === 3) {
      // Investment Considerations
      setCurrentPreviewPages([5]);
    } else if (tabNum === 4) {
      // Leadership
      setCurrentPreviewPages(Array(numLeadershipPages).fill(0).map((_, i) => 7 + i));
    } else if (tabNum === 5) {
      // Products and Services
      setCurrentPreviewPages([8 + numLeadershipPages + productTabNum]);
    } else if (tabNum === 6) {
      // Vendors and Partners
      if (numVendorsPages === 0) {
        setCurrentPreviewPages([]);
      } else {
        setCurrentPreviewPages([8 + numLeadershipPages + numProductPages]);
      }
    } else if (tabNum === 7) {
      // GTM
      if (numGtmPages === 0) {
        setCurrentPreviewPages([]);
      } else {
        setCurrentPreviewPages([
          8 + numLeadershipPages + numProductPages + numVendorsPages,
        ]);
      }
    } else if (tabNum === 8) {
      // Growth
      if (numGrowthPages === 0) {
        setCurrentPreviewPages([]);
      } else {
        setCurrentPreviewPages([
          8 + numLeadershipPages + numProductPages + numVendorsPages + numGtmPages,
        ]);
      }
    } else if (tabNum === 9) {
      // Financials
      setCurrentPreviewPages([
        9 + numLeadershipPages + numProductPages +
          numVendorsPages + numGtmPages + numGrowthPages,
        10 + numLeadershipPages + numProductPages +
          numVendorsPages + numGtmPages + numGrowthPages,
      ]);
    } else if (tabNum === 10) {
      // Revenue
      if (numRevenuePages === 0) {
        setCurrentPreviewPages([]);
      } else if (numRevenuePages === 2) {
        setCurrentPreviewPages([
          11 + numLeadershipPages + numProductPages +
            numVendorsPages + numGtmPages + numGrowthPages,
          12 + numLeadershipPages + numProductPages +
            numVendorsPages + numGtmPages + numGrowthPages,
        ]);
      } else {
        setCurrentPreviewPages([
          11 + numLeadershipPages + numProductPages +
          numVendorsPages + numGtmPages + numGrowthPages,
        ]);
      }
    }
  }, [tabNum, productTabNum, pageNumbers]);

  return (
    <div className={classes.container}>
      <LoadingContainer isLoading={!project}>
        <>
          <div className={classes.header}>
            <div className={classes.title}>
              <IconButton
                onClick={() => navigate(`/clients/${id}`)}
                color='neutral'
                size='lg'
              >
                <ArrowBack />
              </IconButton>
              <Typography level='h3'>CIM</Typography>
            </div>
            <div className={classes.buttons}>
              <Tooltip title="Download PDF">
                <IconButton
                  onClick={() => window.open(cimUrl, '_blank')}
                  color="neutral"
                  variant='outlined'
                  size="lg"
                >
                  <Download />
                </IconButton>
              </Tooltip>
              {project?.cimData?.isCompleted ? (
                <>
                  <Tooltip title="Unlock for Editing">
                    <IconButton
                      onClick={onUnlock}
                      color="warning"
                      variant='solid'
                      size="lg"
                    >
                      <LockOpen />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title="Open Intake">
                    <IconButton
                      onClick={() => window.open(`${config.hosts.client}/${id}/intake`)}
                      color="primary"
                      variant='outlined'
                      size="lg"
                    >
                      <OpenInNew />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Save Changes">
                    <IconButton
                      onClick={onSave}
                      color="success"
                      variant='outlined'
                      size="lg"
                    >
                      <Save />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Finalize">
                    <IconButton
                      onClick={onFinalize}
                      color="success"
                      variant='solid'
                      size="lg"
                    >
                      <CheckCircle />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
          <div className={classes.content}>
            <Tabs
              orientation='vertical'
              className={classes.tabs}
              value={tabNum}
              onChange={(_, value) => setTabNum(value as number)}
            >
              <TabList
                size='lg'
                className={classes.tabList}
              >
                <Tab value={0}>Basic Information</Tab>
                <Tab value={1}>Company Overview</Tab>
                <Tab value={2}>Snapshot</Tab>
                <Tab value={3}>Investment Considerations</Tab>
                <Tab value={4}>Leadership</Tab>
                <Tab value={5}>Products and Services</Tab>
                <Tab value={6}>Vendors and Partners</Tab>
                <Tab value={7}>GTM</Tab>
                <Tab value={8}>Growth</Tab>
                <Tab value={9}>Financials</Tab>
                <Tab value={10}>Revenue</Tab>
              </TabList>
              <TabPanel value={0}>
                <BasicInfo
                  control={control}
                  errors={errors}
                  projectLocked={project?.cimData?.isCompleted ?? false}
                  setValue={setValue}
                  getValues={getValues}
                />
              </TabPanel>
              <TabPanel value={1}>
                <CompanyOverview
                  control={control}
                  errors={errors}
                  projectLocked={project?.cimData?.isCompleted ?? false}
                />
              </TabPanel>
              <TabPanel value={2}>
                <Snapshot
                  control={control}
                  errors={errors}
                  projectLocked={project?.cimData?.isCompleted ?? false}
                />
              </TabPanel>
              <TabPanel value={3}>
                <InvestmentConsiderations
                  control={control}
                  errors={errors}
                  projectLocked={project?.cimData?.isCompleted ?? false}
                />
              </TabPanel>
              <TabPanel value={4}>
                <Leadership
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  projectLocked={project?.cimData?.isCompleted ?? false}
                />
              </TabPanel>
              <TabPanel value={5}>
                <ProductServices
                  productTabNum={productTabNum}
                  setProductTabNum={setProductTabNum}
                  control={control}
                  errors={errors}
                  projectLocked={project?.cimData?.isCompleted ?? false}
                />
              </TabPanel>
              <TabPanel value={6}>
                <Vendors
                  control={control}
                  setValue={setValue}
                  projectLocked={project?.cimData?.isCompleted ?? false}
                  showSections={showSections}
                  setShowSections={setShowSections}
                />
              </TabPanel>
              <TabPanel value={7}>
                <GTM
                  control={control}
                  errors={errors}
                  projectLocked={project?.cimData?.isCompleted ?? false}
                  showSections={showSections}
                  setShowSections={setShowSections}
                />
              </TabPanel>
              <TabPanel value={8}>
                <Growth
                  control={control}
                  errors={errors}
                  projectLocked={project?.cimData?.isCompleted ?? false}
                  showSections={showSections}
                  setShowSections={setShowSections}
                  setValue={setValue}
                />
              </TabPanel>
              <TabPanel value={9}>
                <Financials
                  control={control}
                  projectLocked={project?.cimData?.isCompleted ?? false}
                />
              </TabPanel>
              <TabPanel value={10}>
                <Revenue
                  control={control}
                  projectLocked={project?.cimData?.isCompleted ?? false}
                  showSections={showSections}
                  setShowSections={setShowSections}
                />
              </TabPanel>
            </Tabs>
            {project && (
              <div className={classes.pdfPreviewWrapper}>
                <PDFMultiPageViewer
                  url={cimUrl}
                  widthPerPage={500}
                  showPages={currentPreviewPages}
                  refreshTrigger={refreshTrigger}
                />
              </div>
            )}
          </div>
        </>
      </LoadingContainer>
    </div>
  );
}
