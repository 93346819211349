import {
  Button,
  Theme,
  Typography,
  useTheme,
  List,
  ListItem,
  ListItemButton,
  Chip,
  IconButton,
  Modal,
  ModalDialog,
  ModalClose,
  Tooltip,
  Alert,
} from '@mui/joy';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { tss } from 'tss-react';
import { getAPI } from 'src/api';
import { getConfig } from 'src/config';
import {
  GetProjectResponse,
} from 'src/generated/api';
import LoadingContainer from 'src/components/LoadingContainer';
import {
  ArrowBack,
  Delete,
  Edit,
  Person,
  Add,
} from '@mui/icons-material';
import { SubmitHandler, useForm, useFieldArray } from 'react-hook-form';
import { ControlledInput } from 'src/components/ControlledInput';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      maxWidth: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
    },
    headerLeft: {
      marginLeft: 'auto',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      justifyContent: 'flex-end',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    noBlindProfile: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      marginTop: theme.spacing(2),
      alignItems: 'center',
    },
    blindProfileHeader: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
      marginLeft: 'auto',
      marginTop: theme.spacing(2),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      alignItems: 'flex-end',
    },
    fullWidth: {
      flex: 1,
    },
    list: {
      marginTop: theme.spacing(4),
      boxShadow: theme.shadow.lg,
      overflowY: 'auto',
      maxHeight: '70vh',
    },
    listItem: {
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    listItemText: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      display: 'flex',
      flexDirection: 'column',
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      width: 600,
      gap: theme.spacing(2),
      overflowY: 'auto',
    },
  }));

export default function Client() {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const { id } = useParams();
  const api = getAPI();
  const [error, setError] = React.useState<string | null>(null);
  const [project, setProject] = useState<GetProjectResponse | null>(null);

  const [loading, setIsLoading] = useState(true);
  const [blindProfileLoading, setBlindProfileLoading] = useState(false);
  const [cimLoading, setCimLoading] = useState(false);
  const [buyerListLoading, setBuyerListLoading] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{
    name: string;
    ownerEmail: string;
    advisorName: string;
    advisorTitle: string;
    advisorEmail: string;
    firmName: string;
    callTranscripts: { text: string }[];
  }>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'callTranscripts',
  });

  const navigate = useNavigate();
  const config = getConfig();
  const deleteProject = useCallback(async () => {
    await api.clients.deleteProject(id!);
    navigate('/clients');
  }, [api, id, navigate]);

  const fetchData = useCallback(async () => {
    const res = await api.clients.getProject(id!);
    setProject(res.data);
    if (!res.data.intakeData) {
      // Scraping. Recursively poll till done.
      setTimeout(() => {
        fetchData();
      }, 3000);
    } else {
      setIsLoading(false);
      reset({
        name: res.data.name,
        ownerEmail: res.data.ownerEmail ?? '',
        advisorName: res.data.advisorName ?? '',
        advisorTitle: res.data.advisorTitle ?? '',
        advisorEmail: res.data.advisorEmail ?? '',
        firmName: res.data.firmName ?? '',
        callTranscripts: res.data.transcripts.map((text) => ({ text })),
      });
    }
  }, [api, id, reset]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const generateBlindProfile = useCallback(async () => {
    setBlindProfileLoading(true);
    await api.clients.generateBlindProfile(id!);
    await fetchData();
    setBlindProfileLoading(false);
  }, [api, id, fetchData]);

  const generateCIM = useCallback(async () => {
    setCimLoading(true);
    await api.clients.generateCim(id!);
    await fetchData();
    setCimLoading(false);
  }, [api, id, fetchData]);

  const generateBuyerList = useCallback(async () => {
    setBuyerListLoading(true);
    await api.clients.generateBuyerList(id!);
    await fetchData();
    setBuyerListLoading(false);
  }, [api, id, fetchData]);

  const onSubmit: SubmitHandler<{
    name: string;
    ownerEmail: string;
    advisorName: string;
    advisorTitle: string;
    advisorEmail: string;
    firmName: string;
    callTranscripts: { text: string }[];
  }> = async (data) => {
    setError(null);
    try {
      await api.clients.updateProject(
        id!,
        {
          name: data.name,
          ownerEmail: data.ownerEmail,
          advisorName: data.advisorName,
          advisorTitle: data.advisorTitle,
          advisorEmail: data.advisorEmail,
          firmName: data.firmName,
          transcripts: data.callTranscripts.map((t) => t.text),
        },
      );
      await fetchData();
      setIsRenameModalOpen(false);
    } catch (err: any) {
      setError('A project with this email address already exists');
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <IconButton
          variant='plain'
          onClick={() => navigate('/clients')}
        >
          <ArrowBack />
        </IconButton>
        <div className={classes.title}>
          <Typography level="h3">{project?.name || 'No Name'}</Typography>
          <Typography level="body-lg">{project?.domain}</Typography>
        </div>
        <div className={classes.headerLeft}>
          <div className={classes.buttons}>
            <Tooltip title='Client Portal'>
              <a href={`${config.hosts.client}/${id}`} target='_blank'>
                <IconButton
                  variant='outlined'
                  color='neutral'
                >
                  <Person />
                </IconButton>
              </a>
            </Tooltip>
            <Tooltip title='Edit'>
              <IconButton
                variant='outlined'
                color='primary'
                onClick={() => {
                  setIsRenameModalOpen(true);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title='Delete'>
              <IconButton
                variant='outlined'
                color='danger'
                onClick={deleteProject}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
          <Typography level="body-lg">
            Access Code: {project?.accessCode}
          </Typography>
        </div>
      </div>
      <LoadingContainer isLoading={loading}>
        <List className={classes.list}>
          <ListItem>
            <ListItemButton
              className={classes.listItem}
              onClick={() => navigate(`/clients/${id}/intake`)}
            >
              <Typography level="h4">
                Intake
              </Typography>
              {
                project?.intakeData?.completed ?
                  <Chip size='lg' color='success'>Completed</Chip> :
                  <Chip size='lg' color='primary'>In Progress</Chip>
              }
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              disabled={!project?.intakeData?.completed}
              className={classes.listItem}
              onClick={() => project?.blindProfileData && navigate(`/clients/${id}/blind_profile`)}
            >
              <div className={classes.listItemText}>
                <Typography level="h4">
                  Blind Profile
                </Typography>
                {
                  !project?.intakeData?.completed &&
                  <Typography level="body-sm">
                    Intake must first be completed
                  </Typography>
                }
              </div>
              {
                !project?.blindProfileData && (
                  <Button
                    loading={blindProfileLoading}
                    disabled={!project?.intakeData?.completed}
                    onClick={
                      project?.intakeData?.completed ?
                        (e) => {
                          e.stopPropagation();
                          generateBlindProfile();
                        } :
                        () => {}
                    }
                  >
                    Generate
                  </Button>
                )
              }
              {
                project?.blindProfileData && !project?.blindProfileData.isCompleted && (
                  <Chip size='lg' color='primary'>In Progress</Chip>
                )
              }
              {
                project?.blindProfileData && project?.blindProfileData.isCompleted &&
                !project.blindProfileData.approvedByOwner && (
                  <Chip size='lg' color='warning'>Client Review</Chip>
                )
              }
              {
                project?.blindProfileData && project?.blindProfileData.isCompleted &&
                project.blindProfileData.approvedByOwner && (
                  <Chip size='lg' color='success'>Client Approved</Chip>
                )
              }
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              disabled={!project?.intakeData?.completed}
              className={classes.listItem}
              onClick={() => project?.cimData && navigate(`/clients/${id}/cim`)}
            >
              <div className={classes.listItemText}>
                <Typography level="h4">
                  CIM
                </Typography>
                {
                  !project?.intakeData?.completed &&
                  <Typography level="body-sm">
                    Intake must first be completed
                  </Typography>
                }
              </div>
              {
                !project?.cimData && (
                  <Button
                    loading={cimLoading}
                    disabled={!project?.intakeData?.completed}
                    onClick={
                      project?.intakeData?.completed ?
                        (e) => {
                          e.stopPropagation();
                          generateCIM();
                        } :
                        () => {}
                    }
                  >
                    Generate
                  </Button>
                )
              }
              {
                project?.cimData && !project?.cimData.isCompleted && (
                  <Chip size='lg' color='primary'>In Progress</Chip>
                )
              }
              {
                project?.cimData && project?.cimData.isCompleted &&
                !project.cimData.approvedByOwner && (
                  <Chip size='lg' color='warning'>Client Review</Chip>
                )
              }
              {
                project?.cimData && project?.cimData.isCompleted &&
                project.cimData.approvedByOwner && (
                  <Chip size='lg' color='success'>Client Approved</Chip>
                )
              }
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              className={classes.listItem}
              onClick={() => project?.matchedBuyerList && navigate(`/clients/${id}/buyer_list`)}
            >
              <Typography level="h4">
                Buyer List
              </Typography>
              {
                project?.matchedBuyerList ? (
                  project?.matchedBuyerList?.isCompleted ?
                    <Chip size='lg' color='success'>Completed</Chip> :
                    <Chip size='lg' color='primary'>In Progress</Chip>
                ) :
                  <Button
                    loading={buyerListLoading}
                    onClick={(e) => {
                      e.stopPropagation();
                      generateBuyerList();
                    }}
                  >
                    Generate
                  </Button>
              }
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              className={classes.listItem}
              onClick={() => navigate(`/clients/${id}/data_room`)}
            >
              <Typography level="h4">
                Data Room
              </Typography>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              className={classes.listItem}
              onClick={() => navigate(`/clients/${id}/valuation_calculator`)}
            >
              <Typography level="h4">
                Valuation Calculator
              </Typography>
            </ListItemButton>
          </ListItem>
        </List>
      </LoadingContainer>
      <Modal open={isRenameModalOpen} onClose={() => setIsRenameModalOpen(false)}>
        <ModalDialog>
          <ModalClose />
          <div className={classes.modalContent}>
            <Typography level="h4">Edit Project</Typography>
            {error && (
              <Alert
                color="danger"
                variant="soft"
              >
                {error}
              </Alert>
            )}
            <ControlledInput
              control={control}
              name='name'
              error={errors.name}
              label='Name'
            />
            <ControlledInput
              control={control}
              name='ownerEmail'
              label='Client Email'
              error={errors.ownerEmail}
            />
            <div className={classes.row}>
              <ControlledInput
                required
                label='Advisor Name'
                name='advisorName'
                control={control}
                error={errors.advisorName}
              />
              <ControlledInput
                required
                label='Advisor Title'
                name='advisorTitle'
                control={control}
                error={errors.advisorTitle}
              />
            </div>
            <div className={classes.row}>
              <ControlledInput
                required
                label='Advisor Email'
                name='advisorEmail'
                control={control}
                error={errors.advisorEmail}
              />
              <ControlledInput
                required
                label='Firm Name'
                name='firmName'
                control={control}
                error={errors.firmName}
              />
            </div>
            <Typography level="h4">Call Transcripts</Typography>
            {fields.map((field, index) => (
              <div key={field.id} className={classes.row}>
                <ControlledInput
                  control={control}
                  name={`callTranscripts.${index}.text`}
                  label={`Transcript ${index + 1}`}
                  className={classes.fullWidth}
                  fullWidth
                  textArea
                />
                <IconButton
                  variant='outlined'
                  color='danger'
                  onClick={() => remove(index)}
                >
                  <Delete />
                </IconButton>
              </div>
            ))}
            <Button
              variant='outlined'
              color='success'
              startDecorator={<Add />}
              onClick={() => append({ text: '' })}
            >
              Add Transcript
            </Button>
            <Button onClick={handleSubmit(onSubmit)}>Save</Button>
          </div>
        </ModalDialog>
      </Modal>
    </div>
  );
}
