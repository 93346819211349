import { IntercomProvider, useIntercom } from 'react-use-intercom';
import posthog from 'posthog-js';

import { useLocation } from 'react-router-dom';
import { PostHogProvider } from 'posthog-js/react';
import React, { useContext, useEffect } from 'react';
import { analytics, logEvent } from 'src/analytics';
import constants from 'src/constants';
import { AuthContext } from 'src/contexts/auth';

type Props = {
  children: React.ReactNode;
};

posthog.init(constants.posthogKey, {
  api_host: constants.posthogApiHost,
  ui_host: constants.posthogUiHost,
  session_recording: {
    maskAllInputs: false,
    inlineStylesheet: false,
    maskInputOptions: {
      password: true,
    },
  },
});

function TrackingEffects() {
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const { boot } = useIntercom();
  useEffect(() => {
    if (authContext.user) {
      const traits = {
        email: authContext.user?.email,
        orgId: authContext.org?.id,
        userId: authContext.user?.id,
      };
      posthog.identify(
        authContext.user?.email,
        traits,
      );
      analytics.identify(authContext.user?.email, traits);
    }
    logEvent({
      name: 'app_initial_load',
      type: 'load',
      extra: {
        path: window.location.pathname,
      },
    });
  }, [authContext.user, authContext.org]);
  useEffect(() => {
    logEvent({
      name: 'page_view',
      type: 'impression',
      extra: {
        path: location.pathname,
      },
    });
  }, [location.pathname]);
  useEffect(() => {
    if (user) {
      boot({
        userId: user.id,
        email: user.email,
        name: user.firstName,
        phone: user.phone,
      });
    }
  }, [user, boot]);
  return <></>;
}

export default function Tracking({
  children,
}: Props) {
  return (
    <PostHogProvider client={posthog}>
      <IntercomProvider
        appId='gbiabyqc'
      >
        <TrackingEffects />
        {children}
      </IntercomProvider>
    </PostHogProvider>
  );
}
