/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface WhoAmIResponse {
  org: {
    name: string;
    id: string;
  };
  user: {
    intro: string;
    phone: string;
    email: string;
    lastName: string;
    firstName: string;
    id: string;
  };
}

export enum Type24EnumsAutopilotDataSource {
  WEBSITE = "WEBSITE",
  PITCHBOOK = "PITCHBOOK",
  CRUNCHBASE = "CRUNCHBASE",
  LINKEDIN = "LINKEDIN",
  GOOGLE_REVIEWS = "GOOGLE_REVIEWS",
  YELP = "YELP",
}

export type AutopilotDataSource = Type24EnumsAutopilotDataSource;

export enum Type24EnumsDayOfWeek {
  SUNDAY = "SUNDAY",
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
}

export type DayOfWeek = Type24EnumsDayOfWeek;

export interface GetAutopilotsResponse {
  autopilots: {
    /** @format double */
    size: number;
    name: string;
    id: string;
  }[];
}

/** Construct a type with a set of properties K of type T */
export interface RecordTargetStatusNumber {
  /** @format double */
  NOT_STARTED?: number;
  /** @format double */
  PROCESSING?: number;
  /** @format double */
  FAILED_REQ?: number;
  /** @format double */
  NO_CONTACT_INFO?: number;
  /** @format double */
  WAITING_FOR_APPROVAL?: number;
  /** @format double */
  APPROVED?: number;
  /** @format double */
  SEQUENCE_STARTED?: number;
  /** @format double */
  SEQUENCE_COMPLETED?: number;
  /** @format double */
  REPLIED?: number;
}

export interface GetAutopilotManageResponse {
  excludeCustomerSegment: string[];
  excludeProductService: string[];
  /** @format double */
  revenueFilterMax: number | null;
  /** @format double */
  revenueFilterMin: number | null;
  /** @format double */
  employeeFilterMax: number | null;
  /** @format double */
  employeeFilterMin: number | null;
  geoFilterServiceAreaStates: string[];
  geoFilterHqStates: string[];
  /** @format double */
  maxBusinessReviewedPerDay: number;
  /** @format double */
  maxSuccessPerDay: number;
  daysActive: DayOfWeek[];
  timeWindowTimeZone: string;
  /** @format double */
  timeWindowEndHour: number;
  /** @format double */
  timeWindowStartHour: number;
  autoApprove: boolean;
  sendNewEmails: boolean;
  processNewBusinesses: boolean;
  /** @format double */
  numMatchesToday: number;
  /** @format double */
  numResearchedToday: number;
  /** @format double */
  size: number;
  /** Construct a type with a set of properties K of type T */
  statusCounts: RecordTargetStatusNumber;
  name: string;
  id: string;
}

/**
 * Stringified UUIDv4.
 * See [RFC 4112](https://tools.ietf.org/html/rfc4122)
 * @format uuid
 * @pattern [0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}
 */
export type UUID = string;

export enum TargetStatus {
  NOT_STARTED = "NOT_STARTED",
  PROCESSING = "PROCESSING",
  FAILED_REQ = "FAILED_REQ",
  NO_CONTACT_INFO = "NO_CONTACT_INFO",
  WAITING_FOR_APPROVAL = "WAITING_FOR_APPROVAL",
  APPROVED = "APPROVED",
  SEQUENCE_STARTED = "SEQUENCE_STARTED",
  SEQUENCE_COMPLETED = "SEQUENCE_COMPLETED",
  REPLIED = "REPLIED",
}

export interface GetAutopilotManagePipelineResponse {
  businesses: {
    isSuggested: boolean;
    /** @format double */
    revenue: number | null;
    state: string | null;
    city: string | null;
    /** @format double */
    numEmployees: number | null;
    phone: string;
    description: string;
    domain: string;
    name: string;
    status: TargetStatus;
    id: string;
  }[];
  /** @format double */
  size: number;
}

export interface GetAutopilotManageQueueResponse {
  businesses: {
    isSuggested: boolean;
    /** @format double */
    revenue: number | null;
    state: string | null;
    city: string | null;
    /** @format double */
    numEmployees: number | null;
    phone: string;
    description: string;
    domain: string;
    name: string;
    id: string;
  }[];
  hasAnySequence: boolean;
  /** @format double */
  size: number;
}

export interface GetAutopilotManageResearchResponse {
  requirementCols: {
    dataSources: AutopilotDataSource[];
    ifNotFound: boolean;
    prompt: string;
    name: string;
    id: string;
  }[];
  businesses: {
    /** @format date-time */
    calculatedTime: string;
    requirements: {
      steps: {
        str: string;
        imgUrl: string | null;
      }[];
      explanation: string | null;
      value: any;
      error: string | null;
      id: string;
    }[];
    isProcessing: boolean;
    contactInfo: {
      emailAddress: string | null;
      lastName: string | null;
      firstName: string | null;
    };
    domain: string;
    name: string;
    id: string;
  }[];
  jobTitles: string[];
  /** @format double */
  size: number;
}

export interface GetAutopilotManageEngageResponse {
  autoApprove: boolean;
  subjectInstruction: string;
  senderEmailAddress: string;
  senderLastName: string;
  senderFirstName: string;
  signature: string;
  jobTitles: string[];
  emailCols: {
    /** @format double */
    waitForDays: number;
    examples: string[];
    instruction: string;
    id: string;
  }[];
  businesses: {
    sequence: {
      /** @format date-time */
      calculatedTime: string;
      emails: {
        colId: string;
        /** @format date-time */
        sendTime: string | null;
        value: string;
      }[];
      subject: string;
      approved: boolean;
    };
    contactInfo: {
      emailAddress: string | null;
      lastName: string | null;
      firstName: string | null;
    };
    domain: string;
    name: string;
    id: string;
  }[];
  /** @format double */
  size: number;
}

export interface GetAutopilotViewResponse {
  businesses: {
    domain: string;
    name: string;
    /** @format date-time */
    startDate: string | null;
    status: TargetStatus;
    id: string;
  }[];
  name: string;
}

export interface GetAutopilotBusinessViewResponse {
  requirementsResults: {
    steps: {
      str: string;
      imgUrl: string | null;
    }[];
    explanation: string | null;
    value: any;
    requirement: {
      dataSources: AutopilotDataSource[];
      ifNotFound: boolean;
      prompt: string;
      name: string;
      id: string;
    };
    error: string | null;
  }[];
  status: TargetStatus;
  /** @format date-time */
  calculatedTime: string;
  sequence: {
    emails: {
      trackingEvents: {
        /** @format date-time */
        eventTime: string;
        id: string;
      }[];
      sent: boolean;
      colId: string;
      /** @format date-time */
      sendTime: string | null;
      value: string;
    }[];
    subject: string;
    approved: boolean;
  };
  contactInfo: {
    jobTitle: string | null;
    emailAddress: string | null;
    lastName: string | null;
    firstName: string | null;
  };
  details: {
    crunchbaseUrl: string | null;
    instagramUrl: string | null;
    youtubeUrl: string | null;
    twitterUrl: string | null;
    facebookUrl: string | null;
    gmbUrl: string | null;
    website: string;
    /** @format double */
    funding: number | null;
    /** @format double */
    foundedYear: number | null;
    /** @format double */
    employees: number | null;
    /** @format double */
    revenue: number | null;
    state: string | null;
    city: string | null;
    serviceAreas: string[];
    customerSegments: {
      description: string;
      keyPhrase: string;
    }[];
    productAndServices: {
      description: string;
      keyPhrase: string;
    }[];
    description: string;
  };
  forcePass: boolean;
  name: string;
  id: string;
}

export interface GetSimilarCompaniesResponse {
  businesses: {
    customerSegments: {
      description: string;
      keyPhrase: string;
    }[];
    productsAndServices: {
      description: string;
      keyPhrase: string;
    }[];
    /** @format double */
    avgCustomerSegmentSimilarity: number;
    /** @format double */
    avgProductAndServiceSimilarity: number;
    serviceAreaStates: string[];
    /** @format double */
    totalFunding: number | null;
    /** @format double */
    revenue: number | null;
    state: string | null;
    city: string | null;
    /** @format double */
    numEmployees: number | null;
    description: string;
    domain: string;
    name: string;
    businessId: string;
  }[];
}

export interface GetSubscriptionResponse {
  subscription: {
    willCancel: boolean;
    /** @format double */
    nextBillingDate: number;
    /** @format double */
    monthlyCredits: number;
    /** @format double */
    price: number;
  } | null;
}

export interface GetCreditsLeftResponse {
  /** @format double */
  creditsLeft: number;
}

export interface SimilarSearchDataResponse {
  customerSegments: string[];
  productsAndServices: string[];
}

export interface GetBusinessDetailedResponse {
  lists: string[];
  pppData?: {
    zip: string | null;
    address: string | null;
  };
  crunchbaseData?: {
    fundingRounds: {
      /** @format date-time */
      announcedDate: string;
      /** @format double */
      amountRaised: number | null;
      leadInvestors: string[];
      roundName: string;
    }[];
    founderNames: string[];
    lastFundingType: string | null;
    /** @format date-time */
    lastFundedDate: string | null;
    /** @format double */
    totalFunding: number;
    /** @format double */
    foundedYear: number;
    url: string | null;
  };
  scrapeData: {
    googleLink: string | null;
    yelpLink: string | null;
    youtubeLink: string | null;
    instagramLink: string | null;
    twitterLink: string | null;
    facebookLink: string | null;
    customerSegments: {
      description: string;
      keyPhrase: string;
    }[];
    productsAndServices: {
      description: string;
      keyPhrase: string;
    }[];
    serviceAreas: string[];
    serviceAreaStates: string[];
    sellsToConsumers: boolean;
    sellsToBusinesses: boolean;
    sellsToGovernment: boolean;
    description: string;
  };
  /** @format double */
  numEmployees: number | null;
  /** @format double */
  revenue: number | null;
  gmbUrl: string | null;
  phone: string | null;
  website: string;
  state: string | null;
  city: string | null;
  name: string;
  /**
   * Stringified UUIDv4.
   * See [RFC 4112](https://tools.ietf.org/html/rfc4122)
   */
  id: UUID;
}

export interface SimilarCompaniesResponse {
  /** @format double */
  numSimilar: number;
  similar: {
    description: string;
    name: string;
    id: string;
  }[];
}

export interface SearchResponseItem {
  autopilots: string[];
  lists: string[];
  /** @format double */
  numOverlapServiceAreas: number;
  customerSegmentsInfo: string;
  productsAndServicesInfo: string;
  customerSegments: {
    description: string;
    keyPhrase: string;
  }[];
  productsAndServices: {
    description: string;
    keyPhrase: string;
  }[];
  /** @format double */
  avgCustomerSegmentSimilarity: number;
  /** @format double */
  avgProductAndServiceSimilarity: number;
  /** @format double */
  descriptionSimilarity: number;
  serviceAreaStates: string[];
  /** @format double */
  totalFunding: number | null;
  /** @format double */
  revenue: number | null;
  state: string | null;
  city: string | null;
  /** @format double */
  numEmployees: number | null;
  description: string;
  domain: string;
  name: string;
  businessId: string;
}

export interface SearchResponse {
  /** @format double */
  pageSize: number;
  /** @format double */
  numResults: number;
  results: SearchResponseItem[];
}

export interface SearchResponseItem2 {
  autopilots: string[];
  lists: string[];
  customerSegmentsHighlights: {
    /** @format double */
    length: number;
    /** @format double */
    startIdx: number;
  }[];
  customerSegmentsInfo: string;
  productsAndServicesHighlights: {
    /** @format double */
    length: number;
    /** @format double */
    startIdx: number;
  }[];
  productsAndServicesInfo: string;
  customerSegments: {
    description: string;
    keyPhrase: string;
  }[];
  productsAndServices: {
    description: string;
    keyPhrase: string;
  }[];
  /** @format double */
  hybridRank: number;
  /** @format double */
  textRank: number;
  /** @format double */
  semanticRank: number;
  /** @format double */
  avgCustomerSegmentSimilarity: number;
  /** @format double */
  avgProductAndServiceSimilarity: number;
  /** @format double */
  descriptionSimilarity: number;
  serviceAreaStates: string[];
  /** @format double */
  totalFunding: number | null;
  /** @format double */
  revenue: number | null;
  state: string | null;
  city: string | null;
  /** @format double */
  numEmployees: number | null;
  descriptionHighlights: {
    /** @format double */
    length: number;
    /** @format double */
    startIdx: number;
  }[];
  description: string;
  domain: string;
  name: string;
  businessId: string;
}

export interface SearchResponse2 {
  /** @format double */
  pageSize: number;
  /** @format double */
  numResults: number;
  results: SearchResponseItem2[];
}

export interface RelatedKeywordsResponse {
  customerSegments: string[];
  productsAndServices: string[];
}

export interface GetProjectsResponse {
  projects: {
    intakeCompleted: boolean;
    scraped: boolean;
    name: string;
    domain: string;
    id: string;
  }[];
}

export type InvestmentConsiderations = {
  explanation: string;
  headline: string;
}[];

export type HeadcountByRole = {
  /** @format double */
  numContractors: number;
  /** @format double */
  numPTEs: number;
  /** @format double */
  numFTEs: number;
  role: string;
}[];

export type LeadershipBios = {
  bio: string;
  pictureFileId: string;
}[];

export type RevenueGeoDistribution = {
  /** @format double */
  percentage: number;
  name: string;
}[];

export type AcquisitionChannels = {
  /** @format double */
  percentage: number;
  name: string;
}[];

export type FinancialInfo = {
  /** @format double */
  adjEbitda: number;
  /** @format double */
  grossProfit: number;
  /** @format double */
  revenue: number;
  period: string;
}[];

export type RevenueMix = {
  /** @format double */
  percentage: number;
  name: string;
}[];

export type RecurringRevenue = {
  /** @format double */
  amount: number;
  period: string;
}[];

export type ProspectiveBuyers = {
  email: string | null;
  website: string;
  name: string;
}[];

export type BuyerExclusions = {
  email: string | null;
  website: string;
  name: string;
}[];

export interface ProductService {
  valueProps: string[];
  features: string[];
  description: string;
  name: string;
}

export type GrowthOpportunities = {
  keyPoints: string[];
  title: string;
}[];

export interface AddOnInfo {
  url: string;
  description: string;
  name: string;
}

export interface SponsorInfo {
  pocName: string;
  url: string;
  name: string;
}

export interface MatchedBuyer {
  sponsorsInfo: SponsorInfo[];
  addOnsInfo: AddOnInfo[];
  matchNotes: string;
  /** @format double */
  matchScore: number;
  acquirerDescription: string | null;
  acquirerName: string;
  acquirerWebsite: string;
}

export interface GetProjectResponse {
  matchedBuyerList: {
    matchedBuyers: MatchedBuyer[];
    isCompleted: boolean;
  } | null;
  cimData: {
    growthOppertunities: GrowthOpportunities;
    keyMetricsHighlights: string[];
    marketingStrategyHighlights: string[];
    customerAcquisitionHighlights: string[];
    footerLogoUrl: string | null;
    approvedByOwner: boolean;
    isCompleted: boolean;
    balanceSheetImgUrl: string | null;
    incomeStatementImgUrl: string | null;
    productsAndServices: ProductService[];
    verticalsSummary: string[];
    growthSummary: string[];
    customerSummary: string[];
    peopleSummary: string[];
    investmentConsiderations: string[];
    valueProps: string[];
    companyOverview: string;
    oneLinerDescription: string;
    ownerTitle: string;
    ownerName: string;
    processBlurb: string | null;
    name: string;
  };
  intakeData: {
    showGrowthOpportunities: boolean;
    showCustomerAcquisition: boolean;
    showAwards: boolean;
    showRecurringRevenue: boolean;
    showRevenueMix: boolean;
    showVendorsAndPartners: boolean;
    showCustomerConcentration: boolean;
    showRevenueGeo: boolean;
    completed: boolean;
    certifications: string[];
    awards: string[];
    buyerExclusions: BuyerExclusions;
    prospectiveBuyers: ProspectiveBuyers;
    otherInvestmentConsiderations: string[];
    additionalInfo: string | null;
    recurringRevenueNotes: string | null;
    recurringRevenue: RecurringRevenue;
    revenueNotes: string | null;
    revenueMix: RevenueMix;
    ebitdaAdjustments: string | null;
    financialInfo: FinancialInfo;
    growthOpportunitiesDetails: string | null;
    customerAcquisitionDetails: string | null;
    acquisitionChannels: AcquisitionChannels | null;
    vendersAndPartners: string[];
    verticals: string[];
    revenueConcentrationDetails: string | null;
    topCustomersByRevenue: number[];
    revenueGeoDistribution: RevenueGeoDistribution;
    retentionDescription: string | null;
    /** @format double */
    numCustomers: number | null;
    leadershipBios: LeadershipBios;
    primaryProductDescription: string | null;
    headcountByRole: HeadcountByRole;
    otherPostTransactionPlan: string | null;
    ceoPostTransactionPlan: string | null;
    founderPostTransactionPlan: string | null;
    valuationExpectation: string | null;
    objectiveDescription: string | null;
    companyOverview: string | null;
    corpType: string | null;
    otherLocations: string[];
    hqState: string | null;
    hqCity: string | null;
    incorporationState: string | null;
    /** @format double */
    yearFounded: number | null;
    contactPhoneNumber: string | null;
    contactPersonalEmail: string | null;
    contactWorkEmail: string | null;
    contactTitle: string | null;
    contactName: string | null;
    businessName: string | null;
  };
  blindProfileData: {
    isCompleted: boolean;
    approvedByOwner: boolean;
    headerLogoUrl: string;
    certifications: string[];
    vendorsAndPartners: string[];
    verticals: string[];
    investmentConsiderations: InvestmentConsiderations;
    primaryProductServices: string[];
    description: string;
    subtitle: string;
    name: string;
  } | null;
  accessCode: string;
  name: string;
  transcripts: string[];
  firmName: string;
  advisorEmail: string;
  advisorTitle: string;
  advisorName: string;
  ownerEmail: string | null;
  domain: string;
  id: string;
}

export interface CreateProjectBody {
  transcript?: string;
  firmName: string;
  advisorEmail: string;
  advisorTitle: string;
  advisorName: string;
  ownerEmail: string;
  name: string;
  domain: string;
}

export interface GetClientDataRoomRequestsResponse {
  requests: {
    files: {
      name: string;
    }[];
    isCompleted: boolean;
    preLOI: boolean;
    category: string;
    description: string;
    name: string;
    id: string;
  }[];
}

export interface AdjustedEBITDAInfoResponse {
  possibleAddBacks: {
    questions: string[];
    /** @format double */
    amount: number;
    expenseName: string;
  }[];
  /** @format double */
  totalPossibleAddBacks: number;
  /** @format double */
  initialMultiplier: number;
  ebitdaExplanation: string;
  /** @format double */
  ebitda: number;
}

export interface ValuationInfoResponse {
  multipleAdjustments: {
    operationalTrackRecord: {
      observation: string;
      /** @format double */
      score: number;
    };
    foundersAndManagement: {
      observation: string;
      /** @format double */
      score: number;
    };
    technologyIP: {
      observation: string;
      /** @format double */
      score: number;
    };
    competitiveLandscape: {
      observation: string;
      /** @format double */
      score: number;
    };
    teamOperationalEfficiency: {
      observation: string;
      /** @format double */
      score: number;
    };
    customerAcquisition: {
      observation: string;
      /** @format double */
      score: number;
    };
    customerConcentration: {
      observation: string;
      /** @format double */
      score: number;
    };
    growthOpportunities: {
      observation: string;
      /** @format double */
      score: number;
    };
    recurringRevenue: {
      observation: string;
      /** @format double */
      score: number;
    };
    revenueEbitdaGrowth: {
      observation: string;
      /** @format double */
      score: number;
    };
  };
  expenseAdjustments: {
    explanation: string;
    /** @format double */
    adjustedAmount: number;
    /** @format double */
    originalAmount: number;
    expenseName: string;
  }[];
  adjustedEbitdaExplanation: string;
  /** @format double */
  adjustedEBITDA: number;
}

export interface GetUnauthenticatedPortalDataResponse {
  name: string;
  domain: string;
}

export interface GetPortalDataResponse {
  hasPendingDataRoomRequests: boolean;
  advisorEmail: string;
  advisorName: string;
  cimApproved: boolean;
  cimReady: boolean;
  blindProfileApproved: boolean;
  blindProfileReady: boolean;
  intakeCompleted: boolean;
  name: string;
  domain: string;
}

export interface GetIntakeResponse {
  showCustomerAcquisition: boolean;
  showAwards: boolean;
  showRecurringRevenue: boolean;
  showRevenueMix: boolean;
  showVendorsAndPartners: boolean;
  showCustomerConcentration: boolean;
  showRevenueGeo: boolean;
  isCompleted: boolean;
  additionalInfo: string | null;
  recurringRevenueNotes: string | null;
  recurringRevenue: RecurringRevenue;
  revenueNotes: string | null;
  revenueMix: RevenueMix;
  ebitdaAdjustments: string | null;
  financialInfo: FinancialInfo;
  certifications: string[];
  awards: string[];
  buyerExclusions: BuyerExclusions;
  prospectiveBuyers: ProspectiveBuyers;
  otherInvestmentConsiderations: string[];
  growthOpportunitiesDetails: string | null;
  customerAcquisitionDetails: string | null;
  acquisitionChannels: AcquisitionChannels | null;
  vendersAndPartners: string[];
  verticals: string[] | null;
  revenueConcentrationDetails: string | null;
  topCustomersByRevenue: number[];
  revenueGeoDistribution: RevenueGeoDistribution;
  retentionDescription: string | null;
  leadershipBios: LeadershipBios | null;
  /** @format double */
  numCustomers: number | null;
  primaryProductDescription: string | null;
  headcountByRole: HeadcountByRole | null;
  otherPostTransactionPlan: string | null;
  ceoPostTransactionPlan: string | null;
  founderPostTransactionPlan: string | null;
  valuationExpectation: string | null;
  objectiveDescription: string | null;
  companyOverview: string | null;
  corpType: string | null;
  otherLocations: string[];
  hqState: string | null;
  hqCity: string | null;
  incorporationState: string | null;
  /** @format double */
  yearFounded: number | null;
  contactPhoneNumber: string | null;
  contactPersonalEmail: string | null;
  contactWorkEmail: string | null;
  contactTitle: string | null;
  contactName: string | null;
  businessName: string | null;
}

export interface GetDataRoomResponse {
  requests: {
    files: {
      /** @format date-time */
      uploadedAt: string;
      /** @format double */
      size: number;
      name: string;
    }[];
    isCompleted: boolean;
    preLOI: boolean;
    category: string;
    description: string;
    name: string;
    id: string;
  }[];
}

export interface CreateSignedDataRoomUrlResponse {
  url: string;
}

export enum Type24EnumsEnrichScrapeDataType {
  BOOLEAN = "BOOLEAN",
  STRING = "STRING",
  NUMBER = "NUMBER",
  CLASSIFICATION = "CLASSIFICATION",
}

export type EnrichScrapeDataType = Type24EnumsEnrichScrapeDataType;

export interface EnrichmentClassificationExtra {
  options: {
    description: string;
    name: string;
  }[];
}

export enum Type24EnumsScrapeDataSource {
  WEBSITE = "WEBSITE",
  PITCHBOOK = "PITCHBOOK",
  CRUNCHBASE = "CRUNCHBASE",
  LINKEDIN = "LINKEDIN",
  GOOGLE_REVIEWS = "GOOGLE_REVIEWS",
  YELP = "YELP",
}

export type ScrapeDataSource = Type24EnumsScrapeDataSource;

export interface CreateSignedUrlResponse {
  url: string;
  fileId: string;
}

export interface PrepMeetingResponse {
  typicalEndCustomers: {
    description: string;
    name: string;
  }[];
  keyServices: {
    costFormula: string;
    revenueFormula: string;
    typicalMargin: string;
    businessModel: string;
    targetAudience: string;
    description: string;
    name: string;
  }[];
  locations: string[] | null;
  /** @format double */
  foundingYear: number | null;
  description: string;
  name: string;
}

export interface ScrapeBuyerMatchBusiness {
  customerSegments: string[];
  productServices: string[];
  name: string;
}

export interface ScrapeInboundBusinessResponse {
  customerSegments: string[];
  productServices: string[];
  name: string;
}

export interface PotentialBuyersResponse {
  topMatches: {
    portco: {
      addOns: {
        /** @format date-time */
        dealDate: string | null;
        description: string;
        website: string;
        name: string;
      }[];
      /** @format double */
      score: number;
      /** @format double */
      customerSegmentSimilarity: number;
      customerSegments: string;
      /** @format double */
      productServiceSimilarity: number;
      productServices: string;
      explanation: string;
      description: string;
      website: string;
      name: string;
    };
    firms: {
      website: string;
      name: string;
    }[];
  }[];
  /** @format double */
  numMatches: number;
  inboundBusinessId: string;
}

export interface GetListsResponse {
  results: {
    /** @format double */
    size: number;
    id: string;
    name: string;
  }[];
}

export enum Type24EnumsEnrichStatus {
  QUEUED = "QUEUED",
  RUNNING = "RUNNING",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
  CANCELLED = "CANCELLED",
}

export type EnrichStatus = Type24EnumsEnrichStatus;

export enum Type24EnumsEnrichColType {
  PITCHBOOK = "PITCHBOOK",
  LINKEDIN = "LINKEDIN",
  SCRAPE = "SCRAPE",
  CONTACT_INFO = "CONTACT_INFO",
  PERSONALIZED_MESSAGE = "PERSONALIZED_MESSAGE",
  SIMPLIFIED_NAME = "SIMPLIFIED_NAME",
  AUTOPILOT_EMAIL = "AUTOPILOT_EMAIL",
  IS_BOOTSTRAPPED = "IS_BOOTSTRAPPED",
  IS_VALID = "IS_VALID",
}

export type EnrichColType = Type24EnumsEnrichColType;

export interface GetListResponse {
  enrichmentColumns: {
    personalizedMessage?: {
      instruction: string;
      examples: string[];
    };
    contactInfo?: {
      jobTitles: string[];
    };
    scrape?: {
      useO1: boolean;
      dataSources: ScrapeDataSource[];
      dataTypeExtra: EnrichmentClassificationExtra | null;
      dataType: EnrichScrapeDataType;
      extractInfoTopics: string | null;
      prompt: string;
    };
    type: EnrichColType;
    name: string;
    id: string;
  }[];
  businessImportErrors: {
    error: string | null;
    /** @format double */
    numEmployees: number | null;
    /** @format double */
    funding: number | null;
    /** @format double */
    revenue: number | null;
    state: string | null;
    city: string | null;
    website: string;
    id: string;
  }[];
  /** @format double */
  numBusinessesEnriching: number;
  /** @format double */
  numBusinessesImporting: number;
  businesses: {
    enrichmentValues: {
      isValid?: {
        explanation: string;
        value: boolean;
      };
      isBootstrapped?: {
        value: boolean;
      };
      linkedIn?: {
        size: string;
        /** @format double */
        numFollowers: number;
        /** @format double */
        yearFounded: number | null;
        /** @format double */
        connectedEmployees: number;
        url: string;
      };
      pitchbook?: {
        acquirer: string | null;
        ownershipStatus: string | null;
        latestDealType: string | null;
        financingStatus: string | null;
        /** @format double */
        employeeCount: number | null;
        status: string | null;
        url: string;
      };
      simplifiedName?: {
        result: string;
      };
      personalizedMessage?: {
        result: string;
      };
      contactInfo?: {
        linkedIn: string | null;
        emailAddress: string | null;
        title: string | null;
        lastName: string | null;
        firstName: string | null;
      };
      scrape?: {
        value: any;
      };
      error: string | null;
      colId: string;
      status: EnrichStatus;
    }[];
    customerSegments: {
      description: string;
      keyPhrase: string;
    }[];
    productsAndServices: {
      description: string;
      keyPhrase: string;
    }[];
    serviceAreaStates: string[];
    /** @format double */
    funding: number | null;
    /** @format double */
    revenue: number | null;
    state: string | null;
    city: string | null;
    /** @format double */
    numEmployees: number | null;
    phone: string;
    description: string;
    domain: string;
    name: string;
    id: string;
  }[];
  /** @format double */
  size: number;
  name: string;
}

export interface GetEnrichmentDetails {
  isValid?: {
    explanation: string;
    value: boolean;
  };
  isBootstrapped?: {
    steps: {
      str: string;
      imgUrl: string | null;
    }[];
    pitchbookUrl: string | null;
    crunchbaseUrl: string | null;
    explanation: string;
    value: boolean;
  };
  linkedIn?: {
    size: string;
    /** @format double */
    numFollowers: number;
    /** @format double */
    yearFounded: number | null;
    /** @format double */
    connectedEmployees: number;
    url: string;
  };
  pitchbook?: {
    acquirer: string | null;
    ownershipStatus: string | null;
    latestDealType: string | null;
    financingStatus: string | null;
    /** @format double */
    employeeCount: number | null;
    status: string | null;
    url: string;
  };
  simplifiedName?: {
    result: string;
  };
  personalizedMessage?: {
    result: string;
  };
  contactInfo?: {
    linkedIn: string | null;
    emailAddress: string | null;
    title: string | null;
    lastName: string | null;
    firstName: string | null;
  };
  scrape?: {
    steps: {
      str: string;
      imgUrl: string | null;
    }[];
    explanation: string | null;
    value: any;
  };
  error: string | null;
  colId: string;
  status: EnrichStatus;
}

export interface GetSimilarCompanies {
  businesses: {
    customerSegments: {
      description: string;
      keyPhrase: string;
    }[];
    productsAndServices: {
      description: string;
      keyPhrase: string;
    }[];
    /** @format double */
    avgCustomerSegmentSimilarity: number;
    /** @format double */
    avgProductAndServiceSimilarity: number;
    serviceAreaStates: string[];
    /** @format double */
    totalFunding: number | null;
    /** @format double */
    revenue: number | null;
    state: string | null;
    city: string | null;
    /** @format double */
    numEmployees: number | null;
    description: string;
    domain: string;
    name: string;
    businessId: string;
  }[];
}

export interface GetImportColumnNamesResponse {
  columnNames: string[];
}

export interface StrategicFitResponse {
  lowCategory: string[];
  medCategory: string[];
  highCategory: string[];
  prompt: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "/";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title main
 * @version 1.0.0
 * @license ISC
 * @baseUrl /
 * @contact
 *
 * The main repo
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  auth = {
    /**
     * No description
     *
     * @name Register
     * @request POST:/auth/register
     */
    register: (
      data: {
        accessKey: string;
        password: string;
        emailAddress: string;
        phone: string;
        orgName: string;
        lastName: string;
        firstName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/auth/register`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name Login
     * @request POST:/auth/login
     */
    login: (
      data: {
        password: string;
        email: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/auth/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name Logout
     * @request POST:/auth/logout
     * @secure
     */
    logout: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/logout`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name Me
     * @request GET:/auth/me
     * @secure
     */
    me: (params: RequestParams = {}) =>
      this.request<WhoAmIResponse, any>({
        path: `/auth/me`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateAccount
     * @request POST:/auth/me
     * @secure
     */
    updateAccount: (
      data: {
        intro: string;
        email: string;
        phone: string;
        lastName: string;
        firstName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/auth/me`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ForgotPassword
     * @request POST:/auth/forgot_password
     */
    forgotPassword: (
      data: {
        email: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/auth/forgot_password`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ResetPassword
     * @request POST:/auth/reset_password
     */
    resetPassword: (
      data: {
        token: string;
        password: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/auth/reset_password`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdatePassword
     * @request POST:/auth/update_password
     * @secure
     */
    updatePassword: (
      data: {
        newPassword: string;
        currentPassword: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/auth/update_password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  autopilot = {
    /**
     * No description
     *
     * @name CreateAutopilot
     * @request POST:/autopilot
     * @secure
     */
    createAutopilot: (
      data: {
        /** @format double */
        maxBusinessReviewedPerDay: number;
        /** @format double */
        maxSuccessPerDay: number;
        daysActive: DayOfWeek[];
        timeWindowTimeZone: string;
        /** @format double */
        timeWindowEndHour: number;
        /** @format double */
        timeWindowStartHour: number;
        autoApprove: boolean;
        sendNewEmails: boolean;
        processNewBusinesses: boolean;
        jobTitles: string[];
        emailAddress: string;
        lastName: string;
        firstName: string;
        subjectInstruction: string;
        signature: string;
        emails: {
          /** @format double */
          waitForDays: number;
          instruction: string;
          examples: string[];
        }[];
        requirements: {
          sources: AutopilotDataSource[];
          ifNotFound: boolean;
          prompt: string;
          name: string;
        }[];
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        any
      >({
        path: `/autopilot`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetAutopilots
     * @request GET:/autopilot
     * @secure
     */
    getAutopilots: (params: RequestParams = {}) =>
      this.request<GetAutopilotsResponse, any>({
        path: `/autopilot`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetAutopilotManage
     * @request GET:/autopilot/{id}/manage
     * @secure
     */
    getAutopilotManage: (id: UUID, params: RequestParams = {}) =>
      this.request<GetAutopilotManageResponse, any>({
        path: `/autopilot/${id}/manage`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManagePipeline
     * @request GET:/autopilot/{id}/manage/pipeline
     * @secure
     */
    getManagePipeline: (
      id: UUID,
      query: {
        /** @format double */
        pageNum: number;
        /** @format double */
        pageSize: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetAutopilotManagePipelineResponse, any>({
        path: `/autopilot/${id}/manage/pipeline`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManageQueue
     * @request GET:/autopilot/{id}/manage/queue
     * @secure
     */
    getManageQueue: (
      id: UUID,
      query: {
        /** @format double */
        pageNum: number;
        /** @format double */
        pageSize: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetAutopilotManageQueueResponse, any>({
        path: `/autopilot/${id}/manage/queue`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManageResearch
     * @request GET:/autopilot/{id}/manage/research
     * @secure
     */
    getManageResearch: (
      id: UUID,
      query: {
        /** @format double */
        pageNum: number;
        /** @format double */
        pageSize: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetAutopilotManageResearchResponse, any>({
        path: `/autopilot/${id}/manage/research`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateRequirement
     * @request POST:/autopilot/{campaignId}/requirements
     * @secure
     */
    createRequirement: (
      campaignId: UUID,
      data: {
        sources: AutopilotDataSource[];
        ifNotFound: boolean;
        prompt: string;
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        {
          reason: string;
        }
      >({
        path: `/autopilot/${campaignId}/requirements`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EditRequirement
     * @request PATCH:/autopilot/{campaignId}/requirements/{requirementId}
     * @secure
     */
    editRequirement: (
      campaignId: UUID,
      requirementId: UUID,
      data: {
        sources: AutopilotDataSource[];
        ifNotFound: boolean;
        prompt: string;
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${campaignId}/requirements/${requirementId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteRequirement
     * @request DELETE:/autopilot/{campaignId}/requirements/{requirementId}
     * @secure
     */
    deleteRequirement: (campaignId: UUID, requirementId: UUID, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/autopilot/${campaignId}/requirements/${requirementId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManageEngage
     * @request GET:/autopilot/{id}/manage/engage
     */
    getManageEngage: (
      id: UUID,
      query: {
        /** @format double */
        pageNum: number;
        /** @format double */
        pageSize: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetAutopilotManageEngageResponse, any>({
        path: `/autopilot/${id}/manage/engage`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetAutopilot
     * @request GET:/autopilot/{id}
     */
    getAutopilot: (
      id: UUID,
      query?: {
        /** @default false */
        showFailed?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetAutopilotViewResponse, any>({
        path: `/autopilot/${id}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteCampaign
     * @request DELETE:/autopilot/{id}
     * @secure
     */
    deleteCampaign: (id: UUID, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/autopilot/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name RenameCampaign
     * @request PATCH:/autopilot/{id}
     * @secure
     */
    renameCampaign: (
      id: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetBusinessView
     * @request GET:/autopilot/{campaignId}/business/{businessId}
     */
    getBusinessView: (campaignId: UUID, businessId: UUID, params: RequestParams = {}) =>
      this.request<GetAutopilotBusinessViewResponse, any>({
        path: `/autopilot/${campaignId}/business/${businessId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateEmailCol
     * @request POST:/autopilot/{id}/email
     * @secure
     */
    createEmailCol: (
      id: string,
      data: {
        /** @format double */
        waitForDays: number;
        instruction: string;
        examples: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        {
          reason: string;
        }
      >({
        path: `/autopilot/${id}/email`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EditEmailCol
     * @request PATCH:/autopilot/{id}/email/{colId}
     * @secure
     */
    editEmailCol: (
      id: UUID,
      colId: UUID,
      data: {
        /** @format double */
        waitForDays: number;
        instruction: string;
        examples: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${id}/email/${colId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteEmailCol
     * @request DELETE:/autopilot/{campaignId}/email/{colId}
     * @secure
     */
    deleteEmailCol: (campaignId: UUID, colId: UUID, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/autopilot/${campaignId}/email/${colId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name ConfigureAutopilot
     * @request PATCH:/autopilot/{id}/configure
     * @secure
     */
    configureAutopilot: (
      id: UUID,
      data: {
        /** @format double */
        maxBusinessReviewedPerDay: number;
        /** @format double */
        maxSuccessPerDay: number;
        daysActive: DayOfWeek[];
        timeWindowTimeZone: string;
        /** @format double */
        timeWindowEndHour: number;
        /** @format double */
        timeWindowStartHour: number;
        autoApprove: boolean;
        sendNewEmails: boolean;
        processNewBusinesses: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${id}/configure`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ConfigureSuggestions
     * @request PATCH:/autopilot/{id}/configure_suggestions
     * @secure
     */
    configureSuggestions: (
      id: UUID,
      data: {
        excludeCustomerSegment: string[];
        excludeProductService: string[];
        /** @format double */
        revenueFilterMax: number | null;
        /** @format double */
        revenueFilterMin: number | null;
        /** @format double */
        employeeFilterMax: number | null;
        /** @format double */
        employeeFilterMin: number | null;
        geoFilterServiceAreaStates: string[];
        geoFilterHqStates: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${id}/configure_suggestions`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ConfigureEngage
     * @request PATCH:/autopilot/{id}/engage
     * @secure
     */
    configureEngage: (
      id: UUID,
      data: {
        emailAddress: string;
        lastName: string;
        firstName: string;
        subjectInstruction: string;
        signature: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${id}/engage`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ConfigureAutopilotJobTitles
     * @request PATCH:/autopilot/{id}/job_titles
     * @secure
     */
    configureAutopilotJobTitles: (
      id: UUID,
      data: {
        jobTitles: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${id}/job_titles`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name AddToAutopilot
     * @request POST:/autopilot/{id}/add
     * @secure
     */
    addToAutopilot: (
      id: UUID,
      data: {
        isSuggested?: boolean;
        businessIds: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${id}/add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name AddAllFromSearch
     * @request POST:/autopilot/{campaignId}/add_search
     * @secure
     */
    addAllFromSearch: (
      campaignId: UUID,
      data: {
        searchQuery: {
          softwareOnly: boolean;
          excludeLists?: string[];
          excludeKeywords?: {
            customerSegments: string[];
            productServices: string[];
          };
          fundingFilter?: {
            mustHave: boolean;
            funded?: {
              /** @format double */
              maxFunding: number | null;
              /** @format double */
              minFunding: number | null;
            };
          };
          revenueFilter?: {
            mustHave: boolean;
            /** @format double */
            maxRevenue: number | null;
            /** @format double */
            minRevenue: number | null;
          };
          employeeFilter?: {
            /** @format double */
            maxEmployees: number | null;
            /** @format double */
            minEmployees: number | null;
          };
          geoFilter?: {
            mustHaveServiceAreas: boolean;
            serviceAreasStates: string[];
            hqStates: string[];
          };
          /** @format double */
          customerSegmentWeight: number;
          /** @format double */
          productServiceWeight: number;
          customerSegmentsMatchAll: boolean;
          customerSegments: string[];
          productsAndServicesMatchAll: boolean;
          productsAndServices: string[];
          description: string;
        };
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/autopilot/${campaignId}/add_search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name RemoveFromAutopilot
     * @request POST:/autopilot/{id}/remove
     * @secure
     */
    removeFromAutopilot: (
      id: UUID,
      data: {
        businessIds: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${id}/remove`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name RunSequences
     * @request POST:/autopilot/{id}/run
     * @secure
     */
    runSequences: (
      id: UUID,
      data: {
        businessIds: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        any,
        {
          reason: string;
        }
      >({
        path: `/autopilot/${id}/run`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RegenerateEmail
     * @request POST:/autopilot/{id}/email/run
     * @secure
     */
    regenerateEmail: (
      id: UUID,
      data: {
        businessIds: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        any,
        {
          reason: string;
        }
      >({
        path: `/autopilot/${id}/email/run`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ApproveSequence
     * @request POST:/autopilot/{campaignId}/sequence/{businessId}/approve
     * @secure
     */
    approveSequence: (
      campaignId: UUID,
      businessId: UUID,
      data: {
        approved: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        any,
        {
          reason: string;
        }
      >({
        path: `/autopilot/${campaignId}/sequence/${businessId}/approve`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateEmails
     * @request PATCH:/autopilot/{campaignId}/emails/{businessId}
     * @secure
     */
    updateEmails: (
      campaignId: UUID,
      businessId: UUID,
      data: {
        emails: {
          content: string;
          colId: string;
        }[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${campaignId}/emails/${businessId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateEmail
     * @request PATCH:/autopilot/{campaignId}/{businessId}/email/{colId}
     * @secure
     */
    updateEmail: (
      campaignId: UUID,
      businessId: UUID,
      colId: UUID,
      data: {
        content: string;
        /** @format date-time */
        sendTime: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${campaignId}/${businessId}/email/${colId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name RemoveSuggested
     * @request DELETE:/autopilot/{id}/suggested
     * @secure
     */
    removeSuggested: (
      id: UUID,
      data: {
        businessIds: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/autopilot/${id}/suggested`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name SendEmail
     * @request POST:/autopilot/{campaignId}/send_email
     * @secure
     */
    sendEmail: (
      campaignId: string,
      data: {
        colId: string;
        businessId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${campaignId}/send_email`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateSequenceContactInfo
     * @request PATCH:/autopilot/{campaignId}/{businessId}/contact
     * @secure
     */
    updateSequenceContactInfo: (
      campaignId: string,
      businessId: string,
      data: {
        contactJobTitle: string;
        contactEmailAddress: string;
        contactLastName: string;
        contactFirstName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${campaignId}/${businessId}/contact`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateSequenceSubject
     * @request PATCH:/autopilot/{campaignId}/{businessId}/subject
     * @secure
     */
    updateSequenceSubject: (
      campaignId: string,
      businessId: string,
      data: {
        subject: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${campaignId}/${businessId}/subject`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CompleteImport
     * @request POST:/autopilot/import/{fileId}/complete
     * @secure
     */
    completeImport: (
      fileId: UUID,
      data: {
        mapping: {
          website: string;
        };
        autopilotId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/autopilot/import/${fileId}/complete`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ImportDomains
     * @request POST:/autopilot/{campaignId}/importDomains
     * @secure
     */
    importDomains: (
      campaignId: string,
      data: {
        domains: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/autopilot/${campaignId}/importDomains`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetSuggested
     * @request GET:/autopilot/{campaignId}/suggested
     * @secure
     */
    getSuggested: (campaignId: string, params: RequestParams = {}) =>
      this.request<
        GetSimilarCompaniesResponse,
        {
          reason: string;
        }
      >({
        path: `/autopilot/${campaignId}/suggested`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name OverrideRequirementResult
     * @request PATCH:/autopilot/{campaignId}/{businessId}/override_requirement_result
     * @secure
     */
    overrideRequirementResult: (campaignId: string, businessId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/autopilot/${campaignId}/${businessId}/override_requirement_result`,
        method: "PATCH",
        secure: true,
        ...params,
      }),
  };
  billing = {
    /**
     * No description
     *
     * @name CreateCheckboutSessions
     * @request POST:/billing/session
     * @secure
     */
    createCheckboutSessions: (params: RequestParams = {}) =>
      this.request<
        {
          checkoutUrl: string;
        },
        any
      >({
        path: `/billing/session`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateAdditionalCreditSession
     * @request POST:/billing/additional_credits
     * @secure
     */
    createAdditionalCreditSession: (params: RequestParams = {}) =>
      this.request<
        {
          checkoutUrl: string;
        },
        any
      >({
        path: `/billing/additional_credits`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetSubscription
     * @request GET:/billing/subscription
     * @secure
     */
    getSubscription: (params: RequestParams = {}) =>
      this.request<GetSubscriptionResponse, any>({
        path: `/billing/subscription`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CancelSubscription
     * @request DELETE:/billing/subscription
     * @secure
     */
    cancelSubscription: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/billing/subscription`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetCreditsLeft
     * @request GET:/billing/credits
     * @secure
     */
    getCreditsLeft: (params: RequestParams = {}) =>
      this.request<GetCreditsLeftResponse, any>({
        path: `/billing/credits`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name Webhook
     * @request POST:/billing/webhook
     */
    webhook: (data: any, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/billing/webhook`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  businesses = {
    /**
     * No description
     *
     * @name GetDataForSimilarSearch
     * @request POST:/businesses/similar_search_data
     * @secure
     */
    getDataForSimilarSearch: (
      data: {
        domain: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        SimilarSearchDataResponse,
        {
          reason: string;
        }
      >({
        path: `/businesses/similar_search_data`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetBusinessDetailed
     * @request GET:/businesses/{id}/detailed
     * @secure
     */
    getBusinessDetailed: (id: UUID, params: RequestParams = {}) =>
      this.request<
        GetBusinessDetailedResponse,
        {
          reason: string;
        }
      >({
        path: `/businesses/${id}/detailed`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetSimilar
     * @request GET:/businesses/{id}/similar
     * @secure
     */
    getSimilar: (id: UUID, params: RequestParams = {}) =>
      this.request<
        SimilarCompaniesResponse,
        {
          reason: string;
        }
      >({
        path: `/businesses/${id}/similar`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SearchBusinesses
     * @request POST:/businesses/search
     * @secure
     */
    searchBusinesses: (
      data: {
        /** @format double */
        pageNum: number;
        /** @format double */
        pageSize: number;
        softwareOnly: boolean;
        excludeLists?: string[];
        excludeKeywords?: {
          customerSegments: string[];
          productServices: string[];
        };
        fundingFilter?: {
          mustHave: boolean;
          funded?: {
            /** @format double */
            maxFunding: number | null;
            /** @format double */
            minFunding: number | null;
          };
        };
        revenueFilter?: {
          mustHave: boolean;
          /** @format double */
          maxRevenue: number | null;
          /** @format double */
          minRevenue: number | null;
        };
        employeeFilter?: {
          /** @format double */
          maxEmployees: number | null;
          /** @format double */
          minEmployees: number | null;
        };
        geoFilter?: {
          mustHaveServiceAreas: boolean;
          serviceAreasStates: string[];
          hqStates: string[];
        };
        /** @format double */
        customerSegmentWeight: number;
        /** @format double */
        productServiceWeight: number;
        customerSegmentsMatchAll: boolean;
        customerSegments: string[];
        productsAndServicesMatchAll: boolean;
        productsAndServices: string[];
        description: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SearchResponse, any>({
        path: `/businesses/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SearchBusinesses2
     * @request POST:/businesses/search2
     * @secure
     */
    searchBusinesses2: (
      data: {
        /** @format double */
        pageNum: number;
        /** @format double */
        pageSize: number;
        softwareOnly: boolean;
        excludeLists?: string[];
        fundingFilter?: {
          mustHave: boolean;
          funded?: {
            /** @format double */
            maxFunding: number | null;
            /** @format double */
            minFunding: number | null;
          };
        };
        revenueFilter?: {
          mustHave: boolean;
          /** @format double */
          maxRevenue: number | null;
          /** @format double */
          minRevenue: number | null;
        };
        employeeFilter?: {
          /** @format double */
          maxEmployees: number | null;
          /** @format double */
          minEmployees: number | null;
        };
        geoFilter?: {
          mustHaveServiceAreas: boolean;
          serviceAreasStates: string[];
          hqStates: string[];
        };
        mustHaveTextMatch: boolean;
        mustHaveSemanticMatch: boolean;
        /** @format double */
        semanticSearchWeight: number;
        /** @format double */
        textSearchWeight: number;
        /** @format double */
        customerSegmentSemanticThreshold: number;
        /** @format double */
        customerSegmentWeight: number;
        /** @format double */
        productServiceSemanticThreshold: number;
        /** @format double */
        productServiceWeight: number;
        /** @format double */
        descriptionSemanticThreshold: number;
        /** @format double */
        descriptionWeight: number;
        customerSegments: string;
        productsAndServices: string;
        description: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SearchResponse2, any>({
        path: `/businesses/search2`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name FindRelatedKeywords
     * @request POST:/businesses/related
     * @secure
     */
    findRelatedKeywords: (
      data: {
        customerSegments: string[];
        productServices: string[];
        description: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RelatedKeywordsResponse, any>({
        path: `/businesses/related`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  clients = {
    /**
     * No description
     *
     * @name GetProjects
     * @request GET:/clients
     * @secure
     */
    getProjects: (params: RequestParams = {}) =>
      this.request<GetProjectsResponse, any>({
        path: `/clients`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateProject
     * @request POST:/clients
     * @secure
     */
    createProject: (data: CreateProjectBody, params: RequestParams = {}) =>
      this.request<
        {
          id: string;
        },
        {
          message: string;
        }
      >({
        path: `/clients`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetProject
     * @request GET:/clients/{id}
     * @secure
     */
    getProject: (id: string, params: RequestParams = {}) =>
      this.request<GetProjectResponse, any>({
        path: `/clients/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateProject
     * @request PATCH:/clients/{id}
     * @secure
     */
    updateProject: (
      id: string,
      data: {
        transcripts: string[];
        firmName: string;
        advisorEmail: string;
        advisorTitle: string;
        advisorName: string;
        ownerEmail: string;
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          message: string;
        }
      >({
        path: `/clients/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteProject
     * @request DELETE:/clients/{id}
     * @secure
     */
    deleteProject: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name GenerateBlindProfile
     * @request POST:/clients/{id}/generate_blind_profile
     * @secure
     */
    generateBlindProfile: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/generate_blind_profile`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateBlindProfile
     * @request PATCH:/clients/{id}/blind_profile
     * @secure
     */
    updateBlindProfile: (
      id: string,
      data: {
        showAwards: boolean;
        showRecurringRevenue: boolean;
        showRevenueMix: boolean;
        showVendorsAndPartners: boolean;
        showCustomerConcentration: boolean;
        showRevenueGeo: boolean;
        isCompleted?: boolean;
        headerLogoUrl: string;
        /** @format double */
        numCustomers: number;
        headcountByRole: HeadcountByRole;
        topCustomersByRevenue: number[];
        recurringRevenue: RecurringRevenue;
        financialInfo: FinancialInfo;
        revenueGeoDistribution: RevenueGeoDistribution;
        revenueMix: RevenueMix;
        certifications: string[];
        vendorsAndPartners: string[];
        verticals: string[];
        investmentConsiderations: InvestmentConsiderations;
        primaryProductServices: string[];
        description: string;
        subtitle: string;
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/blind_profile`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GenerateCim
     * @request POST:/clients/{id}/generate_cim
     * @secure
     */
    generateCim: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/generate_cim`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateCim
     * @request PATCH:/clients/{id}/cim
     * @secure
     */
    updateCim: (
      id: string,
      data: {
        showVendorsAndPartners: boolean;
        showGrowthOpportunities: boolean;
        showCustomerAcquisition: boolean;
        showCustomerConcentration: boolean;
        showRevenueGeo: boolean;
        showRevenueMix: boolean;
        growthOppertunities: GrowthOpportunities;
        keyMetricsHighlights: string[];
        marketingStrategyHighlights: string[];
        customerAcquisitionHighlights: string[];
        acquisitionChannels: AcquisitionChannels;
        vendorsAndPartners: string[];
        footerLogoUrl: string;
        topCustomersByRevenue: number[];
        revenueGeoDistribution: RevenueGeoDistribution;
        revenueMix: RevenueMix;
        leadershipBios: LeadershipBios;
        isCompleted?: boolean;
        incomeStatementImgUrl: string;
        balanceSheetImgUrl: string;
        productsAndServices: {
          valueProps: string[];
          features: string[];
          description: string;
          name: string;
        }[];
        verticalsSummary: string[];
        growthSummary: string[];
        customerSummary: string[];
        peopleSummary: string[];
        investmentConsiderations: string[];
        valueProps: string[];
        companyOverview: string;
        oneLinerDescription: string;
        processBlurb: string;
        firmName: string;
        advisorEmail: string;
        advisorTitle: string;
        advisorName: string;
        ownerTitle: string;
        ownerName: string;
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/cim`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteMatchedBuyer
     * @request DELETE:/clients/{id}/matched_buyer
     * @secure
     */
    deleteMatchedBuyer: (
      id: string,
      data: {
        acquirerWebsite: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/matched_buyer`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name AddMatchedBuyer
     * @request POST:/clients/{id}/matched_buyer
     * @secure
     */
    addMatchedBuyer: (
      id: string,
      data: {
        matchNotes: string;
        /** @format double */
        matchScore: number;
        acquirerDescription: string;
        acquirerName: string;
        acquirerWebsite: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/matched_buyer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GenerateBuyerList
     * @request POST:/clients/{id}/generate_buyer_list
     * @secure
     */
    generateBuyerList: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/generate_buyer_list`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name DownloadBlindProfile
     * @request GET:/clients/{id}/download_blind_profile
     * @secure
     */
    downloadBlindProfile: (id: string, params: RequestParams = {}) =>
      this.request<UUID, any>({
        path: `/clients/${id}/download_blind_profile`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DownloadCim
     * @request GET:/clients/{id}/download_cim
     * @secure
     */
    downloadCim: (id: string, params: RequestParams = {}) =>
      this.request<UUID, any>({
        path: `/clients/${id}/download_cim`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SetIntakeComplete
     * @request PATCH:/clients/{id}/intake/complete
     */
    setIntakeComplete: (
      id: string,
      data: {
        completed: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/intake/complete`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name UnlockBlindProfile
     * @request POST:/clients/{id}/unlock_blind_profile
     * @secure
     */
    unlockBlindProfile: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/unlock_blind_profile`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name UnlockCim
     * @request POST:/clients/{id}/unlock_cim
     * @secure
     */
    unlockCim: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/unlock_cim`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name LockBlindProfile
     * @request POST:/clients/{id}/lock_blind_profile
     * @secure
     */
    lockBlindProfile: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/lock_blind_profile`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name LockCim
     * @request POST:/clients/{id}/lock_cim
     * @secure
     */
    lockCim: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/lock_cim`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name LockBuyerList
     * @request POST:/clients/{id}/lock_buyer_list
     * @secure
     */
    lockBuyerList: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/lock_buyer_list`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name UnlockBuyerList
     * @request POST:/clients/{id}/unlock_buyer_list
     * @secure
     */
    unlockBuyerList: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/unlock_buyer_list`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name ExportBuyersCsv
     * @request GET:/clients/{id}/export_buyers_csv
     * @secure
     */
    exportBuyersCsv: (id: string, params: RequestParams = {}) =>
      this.request<UUID, any>({
        path: `/clients/${id}/export_buyers_csv`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateClientDataRoom
     * @request POST:/clients/{id}/data_room
     * @secure
     */
    createClientDataRoom: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/data_room`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetClientDataRoom
     * @request GET:/clients/{id}/data_room
     * @secure
     */
    getClientDataRoom: (id: string, params: RequestParams = {}) =>
      this.request<GetClientDataRoomRequestsResponse, any>({
        path: `/clients/${id}/data_room`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateClientDataRoomRequest
     * @request POST:/clients/{id}/data_room/request
     * @secure
     */
    createClientDataRoomRequest: (
      id: string,
      data: {
        preLOI: boolean;
        category: string;
        description: string;
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/data_room/request`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteClientDataRoomRequest
     * @request DELETE:/clients/{id}/data_room/request/{requestId}
     * @secure
     */
    deleteClientDataRoomRequest: (id: string, requestId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/data_room/request/${requestId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateClientDataRoomRequest
     * @request PATCH:/clients/{id}/data_room/request/{requestId}
     * @secure
     */
    updateClientDataRoomRequest: (
      id: string,
      requestId: string,
      data: {
        preLOI: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/data_room/request/${requestId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetAdjustedEbitdaInfo
     * @request POST:/clients/{id}/get_adjusted_ebitda_info
     * @secure
     */
    getAdjustedEbitdaInfo: (
      id: string,
      data: {
        incomeStatementFileId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AdjustedEBITDAInfoResponse, any>({
        path: `/clients/${id}/get_adjusted_ebitda_info`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CalculateValuation
     * @request POST:/clients/{id}/calculate_valuation
     * @secure
     */
    calculateValuation: (
      id: string,
      data: {
        useO1: boolean;
        addBackAnswers: {
          answer: string;
          question: string;
          /** @format double */
          totalExpenseAmount: number;
          expenseName: string;
        }[];
        /** @format double */
        ebitda: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ValuationInfoResponse, any>({
        path: `/clients/${id}/calculate_valuation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DownloadDataRoomFile
     * @request GET:/clients/{id}/data_room/{requestId}
     * @secure
     */
    downloadDataRoomFile: (id: string, requestId: string, params: RequestParams = {}) =>
      this.request<UUID, any>({
        path: `/clients/${id}/data_room/${requestId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DownloadAllDataRoomFiles
     * @request GET:/clients/{id}/data_room/download_all
     * @secure
     */
    downloadAllDataRoomFiles: (id: string, params: RequestParams = {}) =>
      this.request<UUID, any>({
        path: `/clients/${id}/data_room/download_all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  clientPortal = {
    /**
     * No description
     *
     * @name SignIn
     * @request POST:/client_portal/sign_in
     */
    signIn: (
      data: {
        accessCode: string;
        email: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          clientId: string;
        },
        {
          reason: string;
        }
      >({
        path: `/client_portal/sign_in`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetUnauthenticatedData
     * @request GET:/client_portal/{id}/unauthenticated
     */
    getUnauthenticatedData: (id: string, params: RequestParams = {}) =>
      this.request<GetUnauthenticatedPortalDataResponse, any>({
        path: `/client_portal/${id}/unauthenticated`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name VerifyAccessCode
     * @request POST:/client_portal/{id}/verify_access_code
     */
    verifyAccessCode: (
      id: string,
      data: {
        accessCode: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          isValid: boolean;
        },
        any
      >({
        path: `/client_portal/${id}/verify_access_code`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetPortalData
     * @request GET:/client_portal/{id}
     */
    getPortalData: (id: string, params: RequestParams = {}) =>
      this.request<
        GetPortalDataResponse,
        {
          reason: string;
        }
      >({
        path: `/client_portal/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetIntake
     * @request GET:/client_portal/{id}/intake
     */
    getIntake: (id: string, params: RequestParams = {}) =>
      this.request<
        GetIntakeResponse,
        {
          reason: string;
        }
      >({
        path: `/client_portal/${id}/intake`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateIntake
     * @request PATCH:/client_portal/{id}/intake
     */
    updateIntake: (
      id: string,
      data: {
        showCustomerAcquisition: boolean;
        showAwards: boolean;
        showRecurringRevenue: boolean;
        showRevenueMix: boolean;
        showVendorsAndPartners: boolean;
        showCustomerConcentration: boolean;
        showRevenueGeo: boolean;
        additionalInfo: string;
        certifications: string[];
        awards: string[];
        buyerExclusions: BuyerExclusions;
        prospectiveBuyers: ProspectiveBuyers;
        otherInvestmentConsiderations: string[];
        recurringRevenueNotes: string;
        recurringRevenue: RecurringRevenue;
        revenueNotes: string;
        revenueMix: RevenueMix;
        ebitdaAdjustments: string;
        financialInfo: FinancialInfo;
        growthOpportunitiesDetails: string;
        customerAcquisitionDetails: string;
        acquisitionChannels: AcquisitionChannels;
        vendersAndPartners: string[];
        verticals: string[];
        revenueConcentrationDetails: string;
        topCustomersByRevenue: number[];
        revenueGeoDistribution: RevenueGeoDistribution;
        retentionDescription: string;
        /** @format double */
        numCustomers: number;
        primaryProductDescription: string;
        headcountByRole: HeadcountByRole;
        leadershipBios: LeadershipBios;
        otherPostTransactionPlan: string;
        ceoPostTransactionPlan: string;
        founderPostTransactionPlan: string;
        valuationExpectation: string;
        objectiveDescription: string;
        companyOverview: string;
        corpType: string;
        otherLocations: string[];
        hqState: string;
        hqCity: string;
        incorporationState: string;
        /** @format double */
        yearFounded: number;
        contactPhoneNumber: string;
        contactPersonalEmail: string;
        contactWorkEmail: string;
        contactTitle: string;
        contactName: string;
        businessName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/client_portal/${id}/intake`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetDataRoom
     * @request GET:/client_portal/{id}/data_room
     */
    getDataRoom: (id: string, params: RequestParams = {}) =>
      this.request<
        GetDataRoomResponse,
        {
          reason: string;
        }
      >({
        path: `/client_portal/${id}/data_room`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateSignedUrl
     * @request POST:/client_portal/{id}/data_room/{requestId}/signed_url
     */
    createSignedUrl: (
      id: string,
      requestId: string,
      data: {
        fileName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        CreateSignedDataRoomUrlResponse,
        {
          reason: string;
        }
      >({
        path: `/client_portal/${id}/data_room/${requestId}/signed_url`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateDataRoomFile
     * @request POST:/client_portal/{id}/data_room/{requestId}/file
     */
    createDataRoomFile: (
      id: string,
      requestId: string,
      data: {
        /** @format double */
        size: number;
        fileName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/client_portal/${id}/data_room/${requestId}/file`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DownloadDataRoomFile
     * @request GET:/client_portal/{id}/data_room/{requestId}/{fileName}
     */
    downloadDataRoomFile: (id: string, requestId: string, fileName: string, params: RequestParams = {}) =>
      this.request<
        UUID,
        {
          reason: string;
        }
      >({
        path: `/client_portal/${id}/data_room/${requestId}/${fileName}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RenameDataRoomFile
     * @request PATCH:/client_portal/{id}/data_room/{requestId}/{fileName}
     */
    renameDataRoomFile: (
      id: string,
      requestId: string,
      fileName: string,
      data: {
        newFileName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/client_portal/${id}/data_room/${requestId}/${fileName}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteDataRoomFile
     * @request DELETE:/client_portal/{id}/data_room/{requestId}/{fileName}
     */
    deleteDataRoomFile: (id: string, requestId: string, fileName: string, params: RequestParams = {}) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/client_portal/${id}/data_room/${requestId}/${fileName}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateDataRoomRequestCompleted
     * @request POST:/client_portal/{id}/data_room/{requestId}/complete
     */
    updateDataRoomRequestCompleted: (
      id: string,
      requestId: string,
      data: {
        completed: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/client_portal/${id}/data_room/${requestId}/complete`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DownloadBlindProfile
     * @request GET:/client_portal/{id}/download_blind_profile
     */
    downloadBlindProfile: (id: string, params: RequestParams = {}) =>
      this.request<
        UUID,
        {
          reason: string;
        }
      >({
        path: `/client_portal/${id}/download_blind_profile`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ApproveBlindProfile
     * @request PATCH:/client_portal/{id}/approve_blind_profile
     */
    approveBlindProfile: (
      id: string,
      data: {
        approved: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/client_portal/${id}/approve_blind_profile`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DownloadCim
     * @request GET:/client_portal/{id}/download_cim
     */
    downloadCim: (id: string, params: RequestParams = {}) =>
      this.request<
        UUID,
        {
          reason: string;
        }
      >({
        path: `/client_portal/${id}/download_cim`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ApproveCim
     * @request PATCH:/client_portal/{id}/approve_cim
     */
    approveCim: (
      id: string,
      data: {
        approved: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/client_portal/${id}/approve_cim`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  email = {
    /**
     * No description
     *
     * @name LogEmailView
     * @request GET:/email/{id}/view
     */
    logEmailView: (id: UUID, params: RequestParams = {}) =>
      this.request<
        UUID,
        {
          reason: string;
        }
      >({
        path: `/email/${id}/view`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  enrichments = {
    /**
     * No description
     *
     * @name CreateContactInfo
     * @request POST:/enrichments/{listId}/contact_info
     * @secure
     */
    createContactInfo: (
      listId: UUID,
      data: {
        jobTitles: string[];
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        any
      >({
        path: `/enrichments/${listId}/contact_info`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EditContactInfo
     * @request PATCH:/enrichments/{listId}/contact_info/{colId}
     * @secure
     */
    editContactInfo: (
      listId: UUID,
      colId: UUID,
      data: {
        jobTitles: string[];
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/enrichments/${listId}/contact_info/${colId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateIsBootstrapped
     * @request POST:/enrichments/{listId}/is_bootstrapped
     * @secure
     */
    createIsBootstrapped: (
      listId: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        any
      >({
        path: `/enrichments/${listId}/is_bootstrapped`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EditIsBootstrapped
     * @request PATCH:/enrichments/{listId}/is_bootstrapped/{colId}
     * @secure
     */
    editIsBootstrapped: (
      listId: UUID,
      colId: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/enrichments/${listId}/is_bootstrapped/${colId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateIsValid
     * @request POST:/enrichments/{listId}/is_valid
     * @secure
     */
    createIsValid: (
      listId: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        any
      >({
        path: `/enrichments/${listId}/is_valid`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EditIsValid
     * @request PATCH:/enrichments/{listId}/is_valid/{colId}
     * @secure
     */
    editIsValid: (
      listId: UUID,
      colId: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/enrichments/${listId}/is_valid/${colId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateLinkedIn
     * @request POST:/enrichments/{listId}/linkedin
     * @secure
     */
    createLinkedIn: (
      listId: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        {
          reason: string;
        }
      >({
        path: `/enrichments/${listId}/linkedin`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EditLinkedIn
     * @request PATCH:/enrichments/{listId}/linkedin/{colId}
     * @secure
     */
    editLinkedIn: (
      listId: UUID,
      colId: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/enrichments/${listId}/linkedin/${colId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreatePersonalizedMessage
     * @request POST:/enrichments/{listId}/personalized_message
     * @secure
     */
    createPersonalizedMessage: (
      listId: UUID,
      data: {
        instruction: string;
        examples: string[];
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        any
      >({
        path: `/enrichments/${listId}/personalized_message`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EditPersonalizedMessage
     * @request PATCH:/enrichments/{listId}/personalized_message/{colId}
     * @secure
     */
    editPersonalizedMessage: (
      listId: UUID,
      colId: UUID,
      data: {
        instruction: string;
        examples: string[];
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/enrichments/${listId}/personalized_message/${colId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreatePitchbook
     * @request POST:/enrichments/{listId}/pitchbook
     * @secure
     */
    createPitchbook: (
      listId: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        any
      >({
        path: `/enrichments/${listId}/pitchbook`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EditPitchbook
     * @request PATCH:/enrichments/{listId}/pitchbook/{colId}
     * @secure
     */
    editPitchbook: (
      listId: UUID,
      colId: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/enrichments/${listId}/pitchbook/${colId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateScrape
     * @request POST:/enrichments/{listId}/scrape
     * @secure
     */
    createScrape: (
      listId: UUID,
      data: {
        useO1: boolean;
        dataSources: ScrapeDataSource[];
        dataTypeExtra: EnrichmentClassificationExtra | null;
        dataType: EnrichScrapeDataType;
        extractInfoTopics: string | null;
        prompt: string;
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        any
      >({
        path: `/enrichments/${listId}/scrape`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EditScrape
     * @request PATCH:/enrichments/{listId}/scrape/{colId}
     * @secure
     */
    editScrape: (
      listId: UUID,
      colId: UUID,
      data: {
        useO1: boolean;
        extractInfoTopics: string | null;
        dataSources: ScrapeDataSource[];
        dataTypeExtra: EnrichmentClassificationExtra | null;
        dataType: EnrichScrapeDataType;
        prompt: string;
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/enrichments/${listId}/scrape/${colId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateSimplifiedName
     * @request POST:/enrichments/{listId}/simplified_name
     * @secure
     */
    createSimplifiedName: (
      listId: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        any
      >({
        path: `/enrichments/${listId}/simplified_name`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EditSimplifiedName
     * @request PATCH:/enrichments/{listId}/simplified_name/{colId}
     * @secure
     */
    editSimplifiedName: (
      listId: UUID,
      colId: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/enrichments/${listId}/simplified_name/${colId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  files = {
    /**
     * No description
     *
     * @name CreateSignedUrl
     * @request POST:/files/signed_url
     */
    createSignedUrl: (params: RequestParams = {}) =>
      this.request<CreateSignedUrlResponse, any>({
        path: `/files/signed_url`,
        method: "POST",
        format: "json",
        ...params,
      }),
  };
  health = {
    /**
     * No description
     *
     * @name CheckHealth
     * @request GET:/health
     */
    checkHealth: (params: RequestParams = {}) =>
      this.request<
        {
          message: string;
        },
        any
      >({
        path: `/health`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  internal = {
    /**
     * No description
     *
     * @name PrepMeeting
     * @request POST:/internal/prep
     * @secure
     */
    prepMeeting: (
      data: {
        domain: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PrepMeetingResponse, any>({
        path: `/internal/prep`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ScrapeBusiness
     * @request POST:/internal/scrape_business
     * @secure
     */
    scrapeBusiness: (
      data: {
        domain: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        ScrapeBuyerMatchBusiness,
        {
          reason: string;
        }
      >({
        path: `/internal/scrape_business`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetPotentialBuyers
     * @request POST:/internal/potential_buyers
     * @secure
     */
    getPotentialBuyers: (
      data: {
        customerSegments: string[];
        productServices: string[];
        domain: string;
        businessName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UUID, any>({
        path: `/internal/potential_buyers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ZoomWebhook
     * @request POST:/internal/zoom_meeting_completed
     */
    zoomWebhook: (
      data: {
        payload: any;
        event: string;
        /** @format double */
        event_ts: number;
        download_token?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          encryptedToken: string;
          plainToken: string;
        },
        {
          reason: string;
        }
      >({
        path: `/internal/zoom_meeting_completed`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  landing = {
    /**
     * No description
     *
     * @name ScrapeInboundBusiness
     * @request POST:/landing/scrape_inbound_business
     */
    scrapeInboundBusiness: (
      data: {
        recaptcha: string;
        domain: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        ScrapeInboundBusinessResponse,
        {
          reason: string;
        }
      >({
        path: `/landing/scrape_inbound_business`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetPotentialBuyers
     * @request POST:/landing/potential_buyers
     */
    getPotentialBuyers: (
      data: {
        sessionReplayUrl: string;
        employeeRange: string;
        revenueRange: string;
        customerSegments: string[];
        productServices: string[];
        domain: string;
        businessName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PotentialBuyersResponse,
        {
          reason: string;
        }
      >({
        path: `/landing/potential_buyers`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AddPotentialBuyersContactInfo
     * @request POST:/landing/potential_buyers_contact_info
     */
    addPotentialBuyersContactInfo: (
      data: {
        sessionReplayUrl: string;
        phoneNumber: string;
        emailAddress: string;
        inboundBusinessId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/landing/potential_buyers_contact_info`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  lists = {
    /**
     * No description
     *
     * @name GetLists
     * @request GET:/lists
     * @secure
     */
    getLists: (params: RequestParams = {}) =>
      this.request<GetListsResponse, any>({
        path: `/lists`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateList
     * @request POST:/lists
     * @secure
     */
    createList: (
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        any
      >({
        path: `/lists`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetList
     * @request GET:/lists/{id}
     * @secure
     */
    getList: (
      id: UUID,
      query: {
        /** @format double */
        pageNum: number;
        /** @format double */
        pageSize: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        GetListResponse,
        {
          reason: string;
        }
      >({
        path: `/lists/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteList
     * @request DELETE:/lists/{id}
     * @secure
     */
    deleteList: (id: UUID, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/lists/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name RenameList
     * @request PATCH:/lists/{id}
     * @secure
     */
    renameList: (
      id: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/lists/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetEnrichmentDetails
     * @request GET:/lists/enrichment/{colId}/{businessId}
     * @secure
     */
    getEnrichmentDetails: (colId: UUID, businessId: UUID, params: RequestParams = {}) =>
      this.request<
        GetEnrichmentDetails,
        {
          reason: string;
        }
      >({
        path: `/lists/enrichment/${colId}/${businessId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetSimilarCompanies
     * @request POST:/lists/{id}/similar
     * @secure
     */
    getSimilarCompanies: (
      id: UUID,
      data: {
        excludeCustomerSegment: string[];
        excludeProductService: string[];
        /** @format double */
        revenueFilterMax: number | null;
        /** @format double */
        revenueFilterMin: number | null;
        /** @format double */
        employeeFilterMax: number | null;
        /** @format double */
        employeeFilterMin: number | null;
        geoFilterServiceAreaStates: string[];
        geoFilterHqStates: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        GetSimilarCompanies,
        {
          reason: string;
        }
      >({
        path: `/lists/${id}/similar`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RemoveSimilarBuinesses
     * @request DELETE:/lists/{id}/similar
     * @secure
     */
    removeSimilarBuinesses: (
      id: UUID,
      data: {
        businessIds: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/lists/${id}/similar`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteImportError
     * @request DELETE:/lists/{listId}/import_errors/{id}
     * @secure
     */
    deleteImportError: (listId: UUID, id: UUID, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/lists/${listId}/import_errors/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteAllImportError
     * @request DELETE:/lists/{listId}/import_errors
     * @secure
     */
    deleteAllImportError: (listId: UUID, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/lists/${listId}/import_errors`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteEnrichment
     * @request DELETE:/lists/enrichment/{colId}
     * @secure
     */
    deleteEnrichment: (colId: UUID, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/lists/enrichment/${colId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name AddToList
     * @request POST:/lists/{id}/add
     * @secure
     */
    addToList: (
      id: UUID,
      data: {
        businessIds: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/lists/${id}/add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name RemoveFromList
     * @request POST:/lists/{id}/remove
     * @secure
     */
    removeFromList: (
      id: UUID,
      data: {
        businessIds: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/lists/${id}/remove`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name MoveBusinesses
     * @request POST:/lists/{id}/move
     * @secure
     */
    moveBusinesses: (
      id: UUID,
      data: {
        newListId: string;
        businessIds: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/lists/${id}/move`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DownloadList
     * @request GET:/lists/{id}/download
     * @secure
     */
    downloadList: (id: UUID, params: RequestParams = {}) =>
      this.request<
        UUID,
        {
          reason: string;
        }
      >({
        path: `/lists/${id}/download`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RunEnrichmentColumn
     * @request POST:/lists/{listId}/enrichment/{colId}/run
     * @secure
     */
    runEnrichmentColumn: (
      listId: UUID,
      colId: UUID,
      data: {
        onlyEmptyAndErrors: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        any,
        {
          reason: string;
        }
      >({
        path: `/lists/${listId}/enrichment/${colId}/run`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name StopAllEnrichments
     * @request POST:/lists/{listId}/enrichment/stop
     * @secure
     */
    stopAllEnrichments: (listId: UUID, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/lists/${listId}/enrichment/stop`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name StopAllImports
     * @request POST:/lists/{listId}/imports/stop
     * @secure
     */
    stopAllImports: (listId: UUID, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/lists/${listId}/imports/stop`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name RunSpecificEnrichments
     * @request POST:/lists/{listId}/enrichment/run
     * @secure
     */
    runSpecificEnrichments: (
      listId: UUID,
      data: {
        businessIds: string[];
        colIds: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/lists/${listId}/enrichment/run`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetImportColumnNames
     * @request GET:/lists/import/{fileId}/columns
     * @secure
     */
    getImportColumnNames: (fileId: UUID, params: RequestParams = {}) =>
      this.request<GetImportColumnNamesResponse, any>({
        path: `/lists/import/${fileId}/columns`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CompleteImport
     * @request POST:/lists/import/{fileId}/complete
     * @secure
     */
    completeImport: (
      fileId: UUID,
      data: {
        mapping: {
          numEmployees: string | null;
          funding: string | null;
          revenue: string | null;
          website: string;
          state: string | null;
          city: string | null;
        };
        listId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/lists/import/${fileId}/complete`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ImportDomains
     * @request POST:/lists/{listId}/importDomains
     * @secure
     */
    importDomains: (
      listId: UUID,
      data: {
        domains: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/lists/${listId}/importDomains`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name AddAllFromSearch
     * @request POST:/lists/{listId}/add_search
     * @secure
     */
    addAllFromSearch: (
      listId: UUID,
      data: {
        searchQuery: {
          softwareOnly: boolean;
          excludeLists?: string[];
          excludeKeywords?: {
            customerSegments: string[];
            productServices: string[];
          };
          fundingFilter?: {
            mustHave: boolean;
            funded?: {
              /** @format double */
              maxFunding: number | null;
              /** @format double */
              minFunding: number | null;
            };
          };
          revenueFilter?: {
            mustHave: boolean;
            /** @format double */
            maxRevenue: number | null;
            /** @format double */
            minRevenue: number | null;
          };
          employeeFilter?: {
            /** @format double */
            maxEmployees: number | null;
            /** @format double */
            minEmployees: number | null;
          };
          geoFilter?: {
            mustHaveServiceAreas: boolean;
            serviceAreasStates: string[];
            hqStates: string[];
          };
          /** @format double */
          customerSegmentWeight: number;
          /** @format double */
          productServiceWeight: number;
          customerSegmentsMatchAll: boolean;
          customerSegments: string[];
          productsAndServicesMatchAll: boolean;
          productsAndServices: string[];
          description: string;
        };
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/lists/${listId}/add_search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DuplicateList
     * @request POST:/lists/{id}/duplicate
     * @secure
     */
    duplicateList: (
      id: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        {
          reason: string;
        }
      >({
        path: `/lists/${id}/duplicate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GenerateStrategicFit
     * @request POST:/lists/generate_strategic_fit
     * @secure
     */
    generateStrategicFit: (
      data: {
        otherConsiderations: string;
        targetEndMarkets: string;
        targetProductServices: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<StrategicFitResponse, any>({
        path: `/lists/generate_strategic_fit`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
