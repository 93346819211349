import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAPI } from 'src/api';
import { GetClientDataRoomRequestsResponse } from 'src/generated/api';
import { tss } from 'tss-react';
import {
  Theme,
  useTheme,
  Table,
  Sheet,
  Typography,
  Select,
  Option,
  Button,
  IconButton,
  Modal,
  Box,
  ModalDialog,
  ModalClose,
  FormControl,
  FormLabel,
} from '@mui/joy';
import {
  ChevronLeft,
  Add,
  Delete,
  CheckCircle,
  Download,
} from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { ControlledInput } from 'src/components/ControlledInput';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      maxWidth: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      width: '100%',
      justifyContent: 'space-between',
      gap: theme.spacing(2),
    },
    category: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    tableCell: {
      padding: theme.spacing(1, 2),
      textAlign: 'left',
    },
    nameCell: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    nameCellRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: 500,
      gap: theme.spacing(1),
    },
    table: {
      marginTop: theme.spacing(2),
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
    },
  }));

type NewRequestForm = {
  name: string;
  description: string;
  category: string;
  preLOI: boolean;
};

const Categories = [
  'Financial Information',
  'Sales and Marketing',
  'Company Overview',
  'Human Resources',
  'Legal Documents',
] as const;

export default function DataRoom() {
  const { id } = useParams();
  const [data, setData] = useState<GetClientDataRoomRequestsResponse>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const api = getAPI();
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
  } = useForm<NewRequestForm>({
    defaultValues: {
      name: '',
      description: '',
      category: '',
      preLOI: true,
    },
  });

  const fetch = useCallback(async () => {
    const res = await api.clients.getClientDataRoom(id!);
    setData(res.data);
  }, [id, api]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleGenerateDataRoom = async () => {
    await api.clients.createClientDataRoom(id!);
    fetch();
  };

  const handleUpdatePreLOI = async (requestId: string, preLOI: boolean) => {
    await api.clients.updateClientDataRoomRequest(id!, requestId, { preLOI });
    fetch();
  };

  const handleDeleteRequest = async (requestId: string) => {
    await api.clients.deleteClientDataRoomRequest(id!, requestId);
    fetch();
  };

  const handleAddRequest = async (formData: NewRequestForm) => {
    await api.clients.createClientDataRoomRequest(id!, formData);
    setIsModalOpen(false);
    reset();
    fetch();
  };

  const handleDownloadAll = useCallback(async () => {
    const response = await api.clients.downloadAllDataRoomFiles(
      id!,
      {
        format: 'blob',
      },
    );
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${id}_all_data_room_files.zip`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }, [id, api.clients]);

  const handleDownloadRequest = useCallback(async (requestId: string) => {
    const response = await api.clients.downloadDataRoomFile(
      id!,
      requestId,
      {
        format: 'blob',
      },
    );
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${id}_data_room_${requestId}.zip`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }, [id, api.clients]);

  const groupedRequests = data?.requests.reduce((acc, request) => {
    if (!acc[request.category]) {
      acc[request.category] = [];
    }
    acc[request.category].push(request);
    return acc;
  }, {} as Record<string, typeof data.requests>);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.title}>
          <IconButton onClick={() => navigate(`/clients/${id}`)}>
            <ChevronLeft />
          </IconButton>
          <Typography level="h2">Data Room</Typography>
        </div>
        <div className={classes.actions}>
          {Boolean(data?.requests.length) && (
            <>
              <Button
                onClick={handleDownloadAll}
                startDecorator={<Download />}
                variant="outlined"
                color="neutral"
              >
                Download All
              </Button>
              <Button
                onClick={() => setIsModalOpen(true)}
                startDecorator={<Add />}
                variant="outlined"
                color="success"
              >
                Add Request
              </Button>
            </>
          )}
        </div>
      </div>

      {!data?.requests.length ? (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography level="body-lg" mb={2}>No data room requests.</Typography>
          <Button
            size="lg"
            onClick={handleGenerateDataRoom}
          >
            Generate
          </Button>
        </Box>
      ) : (
        Object.entries(groupedRequests || {}).map(([category, requests]) => (
          <Sheet key={category} className={classes.category}>
            <Typography level="h3">{category}</Typography>
            <Table className={classes.table}>
              <tbody>
                {requests.map((request) => (
                  <tr key={request.id}>
                    <td
                      style={{ flexGrow: 1 }}
                      className={classes.tableCell}
                    >
                      <div className={classes.nameCell}>
                        <div className={classes.nameCellRow}>
                          <Typography level="body-md">
                            <b>
                              {request.name}
                            </b>
                          </Typography>
                          {
                            request.isCompleted && (
                              <CheckCircle
                                color="success"
                              />
                            )
                          }
                        </div>
                        <Typography level="body-sm">
                          {request.description}
                        </Typography>
                      </div>
                    </td>
                    <td
                      style={{ width: 180 }}
                      className={classes.tableCell}
                    >
                      <Select
                        value={request.preLOI ? 'pre' : 'post'}
                        onChange={(_, value) => handleUpdatePreLOI(request.id, value === 'pre')}
                      >
                        <Option value="pre">Pre-LOI</Option>
                        <Option value="post">Post-LOI</Option>
                      </Select>
                    </td>
                    <td
                      className={classes.tableCell}
                      style={{ width: 100 }}
                    >
                      <div className={classes.actions}>
                        <IconButton
                          onClick={() => handleDownloadRequest(request.id)}
                          variant="outlined"
                          color="primary"
                          disabled={!request.isCompleted}
                        >
                          <Download />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDeleteRequest(request.id)}
                          variant="outlined"
                          color="danger"
                        >
                          <Delete />
                        </IconButton>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        ))
      )}

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalDialog>
          <ModalClose />
          <Typography level="h4" mb={2}>Add File Request</Typography>
          <form onSubmit={handleSubmit(handleAddRequest)} className={classes.form}>
            <ControlledInput
              name="name"
              label="Name"
              control={control}
              required
            />
            <ControlledInput
              name="description"
              label="Description"
              control={control}
              required
              textArea
            />
            <FormControl>
              <FormLabel>Category</FormLabel>
              <Select
                name="category"
                defaultValue=""
                onChange={(_, value) => setValue('category', value as string)}
                required
              >
                {Categories.map((category) => (
                  <Option key={category} value={category}>
                    {category}
                  </Option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Pre-LOI</FormLabel>
              <Select
                name="preLOI"
                defaultValue={true}
                onChange={(_, value) => setValue('preLOI', value as boolean)}
              >
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </FormControl>
            <Button type="submit" fullWidth>Add Request</Button>
          </form>
        </ModalDialog>
      </Modal>
    </div>
  );
}
