type Config = {
  hosts: {
    landing: string;
    app: string;
    api: string;
    client: string;
  };
};

// TODO: At some point, we should
// 1. Move to a secrets system
// 2. Rotate all the passwords here
const production = {
  hosts: {
    landing: 'https://offdeal.io',
    app: 'https://app.offdeal.io',
    api: 'https://api.offdeal.io',
    client: 'https://client.offdeal.io',
  },
};

const development = {
  hosts: {
    landing: 'http://localhost:3000',
    app: 'http://localhost:3001',
    api: 'http://localhost:3002',
    client: 'http://localhost:3004',
  },
};

export function getConfig(): Config {
  if (process.env.NODE_ENV === 'production') {
    return production;
  }
  return development;
}
